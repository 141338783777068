import { useState } from 'react';

import { useWebSocket } from './sockets/ws-provider';
import { useGlobalState } from './globalStateProvider';
import useInterval from './useInterval';
import useMockFocus from './useMockFocus';

interface UseMindControl {
  focusLevel: number;
  headsetDetected: boolean;
  isRunning: boolean;
  start: () => void;
  pause: () => void;
}

const useMindControl = (
  useMockedData = false,
  secondsRemaining = 0
): UseMindControl => {
  const ws = useWebSocket();
  const { localHeadsetConnected, localHeadsetFocus, pourParams } =
    useGlobalState();
  const { pourDurationSeconds, gamePracticeDuration } = pourParams;
  const secondsTotal = pourDurationSeconds + gamePracticeDuration;
  const [focusLevel, setFocusLevel] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  // FIXME: another method for finding if headset is detected,
  // poorSignalLevel, or request a value greater than 0
  const headsetDetected = !isRunning && focusLevel > 0;

  const percentageTimeElapsed =
    (secondsTotal - secondsRemaining) / secondsTotal;
  const mockFocus = useMockFocus(useMockedData, percentageTimeElapsed);

  const start = () => {
    setIsRunning(true);
    console.log('start mind control');
  };

  const pause = () => {
    setIsRunning(false);
    console.log('pause mind control');
  };

  useInterval(
    () => {
      if (!useMockedData) {
        if (
          ws.lastMessage.type === 'mind_reading' &&
          typeof ws.lastMessage.data != 'object' &&
          +ws.lastMessage.data > 0
        ) {
          const val =
            typeof ws.lastMessage.data === 'string'
              ? parseFloat(ws.lastMessage.data)
              : ws.lastMessage.data;
          // We could consider using ws.lastMessage.signalLevel here to speed up headset recognition
          setFocusLevel(val);
        } else if (localHeadsetConnected) {
          setFocusLevel(localHeadsetFocus);
        }
      } else if (mockFocus) {
        // Mocked data
        setFocusLevel(mockFocus);
      }
    },
    !headsetDetected ? 200 : isRunning ? 48 : null
  );

  return {
    focusLevel: focusLevel,
    headsetDetected,
    isRunning,
    start,
    pause,
  };
};

export default useMindControl;

import {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import chroma from 'chroma-js';

import { ConnectionStatusData } from './sockets/ws-provider';
import { Account, Event } from './types/db-models';
import { supabase } from './db/client';
import useLocalStorage from './useLocalStorage';
import { MessagesMessage, VaultMessage } from '../components/pages/VaultScreen';

export enum AppState {
  Mind,
  Body,
  Button,
  Home,
  Config,
  Race,
  Safe,
}

export enum PersonalDetailEntry {
  None,
  Form,
  QrCode,
}

export enum GameMode {
  Beer,
  Coffee,
  Swing,
}

export enum SafeScoreAlgorithm {
  Average,
  Max,
  Min,
  AverageNoZero,
}

export enum SafeVersion {
  V1,
  V2,
}

export type SelectableState =
  | AppState.Body
  | AppState.Mind
  | AppState.Race
  | AppState.Safe
  | AppState.Button;

export const DEFAULT_MIN_ATTENTION = 0;
export const ATTENTION_EXPONENT = 3;
export const DEFAULT_MAX_ATTENTION = Math.pow(0.25, ATTENTION_EXPONENT);
export const MAX_ATTENTION_MULTIPLIER = 1.5;
export const DEFAULT_MIN_DROPPERS = 1;
export const DEFAULT_MAX_DROPPERS = 7;

export const transformToRgb = (colour: string): [number, number, number] => [
  ...chroma(colour).rgb(),
];

export const transformSafeColourToRgbArray = (
  colour: SafeColour,
  customColour: string
): [number, number, number][] => {
  if (colour === 'custom') {
    const f = chroma.scale([customColour, '#000000']);
    return [0, 0.5, 0.75, 0.875, 0.9375].map(
      (v) => [...f(v).rgb()] as [number, number, number]
    );
  }
  return SAFE_COLOURS[colour].map(
    (v) => [...chroma(v).rgb()] as [number, number, number]
  );
};

export type SafeColour =
  | 'rainbow'
  | 'basic'
  | 'lavender-dream'
  | 'september-fire'
  | 'custom';

export const SAFE_COLOUR_OPTIONS: Record<SafeColour, string> = {
  rainbow: 'Rainbow',
  basic: 'Basic Colours',
  'lavender-dream': 'Lavender Dream',
  'september-fire': 'September Fire',
  custom: 'Custom',
};

export const SAFE_COLOURS: Record<SafeColour, string[]> = {
  rainbow: [
    'rgb(125 0 0)',
    'rgb(125 25 0)',
    'rgb(125 90 0)',
    'rgb(0 125 0)',
    'rgb(0 50 40)',
    'rgb(0 0 125)',
    'rgb(80 0 125)',
    'rgb(125 0 50)',
  ],
  basic: ['rgb(125 0 0)', 'rgb(125 125 0)', 'rgb(0 125 0)', 'rgb(0 0 125)'],
  'lavender-dream': [
    'rgb(0 0 125)',
    'rgb(40 0 125)',
    'rgb(75 0 125)',
    'rgb(75 25 100)',
    'rgb(75 50 75)',
  ],
  'september-fire': [
    'rgb(125 0 0)',
    'rgb(125 5 0)',
    'rgb(125 25 0)',
    'rgb(125 50 0)',
    'rgb(125 75 0)',
  ],
  custom: [],
};

export interface SafeColourStop {
  stop: number;
  colour: string;
}

export interface PourParams {
  pourDurationSeconds: number;
  gamePracticeDuration: number;
  maxGameDurationSeconds: number;
  glassBestAngle: number;
  glassWorstAngle: number;
  tapOffAngle: number;
  tapOnAngle: number;
  minFocus: number;
  minTapFocus: number;
  minScoreMapping: number;
  practiceGlassMove: boolean;
  minAttention: number;
  maxAttention: number;
  minDroppers: number;
  maxDroppers: number;
  coffeeLowAngle: number;
  coffeeHighAngle: number;
  coffeeFullRotationDurationMs: number;
  coffeeMinimumSwingAngle: number;
  safeVersion: SafeVersion;
  safeGameDuration: number;
  safeScoreIntervalSeconds: number;
  safeScoreSampleSize: number;
  safeScoreAlgorithm: SafeScoreAlgorithm;
  safeLockThreshold1: number;
  safeLockTime1: number;
  safeLockThreshold2: number;
  safeLockTime2: number;
  safeLockThreshold3: number;
  safeLockTime3: number;
  safeDialRotationInterval: number;
  safeDialRotationAmount: number;
  safeDefaultColour: string;
  safeInProgressColour: string;
  safeCompletedColour: string;
  safeScreenChannel: string;
  safeIdleSelection: SafeColour;
  safeIdleCustomColour: string;
  safeIdleInterval: number;
  safeCompleteSelection: SafeColour;
  safeCompleteCustomColour: string;
  safeCompleteInterval: number;
}

export interface VaultScreenParams {
  safeInitialMessage: string;
  safeWinMessage: string;
  safeLoseMessage: string;
  safeScreenColour: string;
  safeMessageAmount: number;
  safeMessageInterval: number;
}

export interface Dialog {
  rank: string;
  minScore: number;
  maxScore: number;
  text: string;
}

export const initialEndGameDialogs: Dialog[] = [
  {
    rank: 'BEGINNER',
    text: 'Wow. That was bad...Try again?',
    minScore: 0,
    maxScore: 400,
  },
  {
    rank: 'INTERMEDIATE',
    text: 'Hmm, not good, not bad - just OK',
    minScore: 401,
    maxScore: 750,
  },
  {
    rank: 'PRO',
    text: 'Wow. Pretty great!',
    minScore: 751,
    maxScore: 900,
  },
  {
    rank: 'JEDI',
    text: 'The force is strong with you!',
    minScore: 901,
    maxScore: 10000,
  },
];

const initialParams: PourParams = {
  pourDurationSeconds: 16,
  gamePracticeDuration: 15,
  maxGameDurationSeconds: 16,
  glassBestAngle: 1625,
  glassWorstAngle: 1500,
  tapOffAngle: 1440,
  tapOnAngle: 1550,
  minFocus: 10,
  minTapFocus: 0,
  minScoreMapping: 10,
  practiceGlassMove: false,
  minAttention: DEFAULT_MIN_ATTENTION,
  maxAttention: DEFAULT_MAX_ATTENTION,
  minDroppers: DEFAULT_MIN_DROPPERS,
  maxDroppers: DEFAULT_MAX_DROPPERS,
  coffeeLowAngle: 1000,
  coffeeHighAngle: 2295,
  coffeeFullRotationDurationMs: 550,
  coffeeMinimumSwingAngle: 200,
  safeVersion: SafeVersion.V1,
  safeGameDuration: 120,
  safeScoreIntervalSeconds: 0.2,
  safeScoreSampleSize: 5,
  safeScoreAlgorithm: SafeScoreAlgorithm.Average,
  safeLockThreshold1: 40,
  safeLockTime1: 10,
  safeLockThreshold2: 60,
  safeLockTime2: 10,
  safeLockThreshold3: 75,
  safeLockTime3: 10,
  safeDialRotationInterval: 0.5,
  safeDialRotationAmount: 10,
  safeDefaultColour: '#7f0000',
  safeInProgressColour: '#7f7f7f',
  safeCompletedColour: '#007f00',
  safeScreenChannel: 'mv2-game-1',
  safeIdleSelection: 'rainbow',
  safeIdleCustomColour: '#ffffff',
  safeIdleInterval: 0.5,
  safeCompleteSelection: 'rainbow',
  safeCompleteCustomColour: '#007f00',
  safeCompleteInterval: 0.5,
};

const initialVaultScreenParams = {
  safeInitialMessage: 'Mind Vault - Presented by Arcade Strange',
  safeWinMessage: 'You win!',
  safeLoseMessage: 'You lose!',
  safeScreenColour: '#ffffff',
  safeMessageAmount: 1000,
  safeMessageInterval: 60,
};

interface GlobalState {
  account: Account | null;
  setAccount: (a: Account) => void;
  currentEvent: Event | null;
  setCurrentEvent: (e: Event | null) => void;
  currentMode: AppState;
  websocketUrl: string;
  setWebsocketUrl: (s: string) => void;
  skin: string;
  setSkin: (s: string) => void;
  defaultMode: AppState;
  setDefaultMode: (newMode: AppState) => void;
  defaultSkin: string;
  setDefaultSkin: (newSkin: string) => void;
  hasRobotConnection: boolean;
  setCurrentMode: (newMode: AppState) => void;
  setConnectionStatus: (newStatus: boolean) => void;
  connectionStatus: boolean;
  setRobotDemoMode: (newRobotDemoMode: boolean) => void;
  robotDemoMode: boolean;
  setSkipPreGamePersonalDetailEntry: (newSkip: boolean) => void;
  skipPreGamePersonalDetailEntry: boolean;
  setCollectEmail: (newCollectEmail: boolean) => void;
  collectEmail: boolean;
  setUseSoftwareKeyboard: (newUse: boolean) => void;
  useSoftwareKeyboard: boolean;
  setPreGamePersonalDetailEntry: (
    newPersonalDetailEntry: PersonalDetailEntry
  ) => void;
  preGamePersonalDetailEntry: PersonalDetailEntry;
  setPostGamePersonalDetailEntry: (
    newPersonalDetailEntry: PersonalDetailEntry
  ) => void;
  postGamePersonalDetailEntry: PersonalDetailEntry;
  interruptPour: boolean;
  setInterruptPour: (b: boolean) => void;
  gameMode: GameMode;
  setGameMode: (m: GameMode) => void;
  cupDetected: boolean;
  setCupDetected: (b: boolean) => void;
  hasConnectionError: boolean;
  setHasConnectionError: (b: boolean) => void;
  pourParams: PourParams;
  setPourParams: (p: PourParams | ((p: PourParams) => PourParams)) => void;
  vaultScreenParams: VaultScreenParams;
  setVaultScreenParams: (
    p: VaultScreenParams | ((p: VaultScreenParams) => VaultScreenParams)
  ) => void;
  endGameDialogs: Dialog[];
  setEndGameDialogs: (d: Dialog[] | ((d: Dialog[]) => Dialog[])) => void;
  connectionStatusData: ConnectionStatusData;
  setConnectionStatusData: (c: ConnectionStatusData) => void;
  toggleCursorVisibility: () => void;
  setMockMindControl: (b: boolean | ((b: boolean) => boolean)) => void;
  mockMindControl: boolean;
  headsetList: string[];
  setHeadsetList: (s: string[]) => void;
  localHeadsetConnected: boolean;
  setLocalHeadsetConnected: (b: boolean) => void;
  localHeadsetFocus: number;
  setLocalHeadsetFocus: (f: number | ((f: number) => number)) => void;
  resetAttentionThresholds: () => void;
  resetDropperThresholds: () => void;
  enableVaultScreen: boolean;
  setEnableVaultScreen: (b: boolean) => void;
  sendVaultStatusMessage: (message: VaultMessage) => void;
  sendVaultMessagesMessage: (message: MessagesMessage) => void;
}

export const GlobalStateContext = createContext({
  hasRobotConnection: false,
} as GlobalState);

export const useGlobalState = (): GlobalState => useContext(GlobalStateContext);

export const GlobalStateProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [account, setAccount] = useState<Account | null>(null);
  const [defaultMode, setDefaultMode] = useLocalStorage(
    'as-default-mode',
    AppState.Mind
  );
  const [defaultSkin, setDefaultSkin] = useLocalStorage('as-default-skin', '');
  const [websocketUrl, setWebsocketUrl] = useLocalStorage(
    'as-websocket-url',
    process.env.REACT_APP_WEBSOCKET_URL ?? 'wss://localhost:9092'
  );
  const [currentMode, setCurrentMode] = useState<AppState>(AppState.Home);
  const [skin, setSkin] = useState<string>('');
  const [robotDemoMode, setRobotDemoMode] = useState(false);
  const [hasConnection, setHasConnection] = useState(false);
  const [interruptPour, setInterruptPour] = useState(false);
  const [enableVaultScreen, setEnableVaultScreen] = useState(false);
  const [mockMindControl, setMockMindControl] = useLocalStorage(
    'as-mock-mind-control',
    false
  );
  const [skipPreGamePersonalDetailEntry, setSkipPreGamePersonalDetailEntry] =
    useLocalStorage('as-skip-pre-game-personal-detail-entry', false);
  const [collectEmail, setCollectEmail] = useLocalStorage(
    'as-collect-email',
    true
  );
  const [useSoftwareKeyboard, setUseSoftwareKeyboard] = useLocalStorage(
    'as-software-keyboard',
    true
  );
  const [gameMode, setGameMode] = useLocalStorage(
    'as-game-mode',
    GameMode.Beer
  );
  const [preGamePersonalDetailEntry, setPreGamePersonalDetailEntry] =
    useLocalStorage(
      'as-pre-game-personal-detail-entry',
      PersonalDetailEntry.None
    );
  const [postGamePersonalDetailEntry, setPostGamePersonalDetailEntry] =
    useLocalStorage(
      'as-post-game-personal-detail-entry',
      PersonalDetailEntry.QrCode
    );
  const [headsetList, setHeadsetList] = useState<string[]>([]);
  const [localHeadsetConnected, setLocalHeadsetConnected] =
    useState<boolean>(false);
  const [localHeadsetFocus, setLocalHeadsetFocus] = useState<number>(0);
  const [cupDetected, setCupDetected] = useState(true); //TODO: change?
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const [pourParams, setPourParams] = useLocalStorage(
    'as-pour-params',
    initialParams
  );
  const [vaultScreenParams, setVaultScreenParams] = useLocalStorage(
    'as-vault-screen-params',
    initialVaultScreenParams
  );
  const [endGameDialogs, setEndGameDialogs] = useState<Dialog[]>(
    initialEndGameDialogs
  );
  const [connectionStatusData, setConnectionStatusData] =
    useState<ConnectionStatusData>({
      headset_connection: 200,
      robot_connected: false,
    });
  const [cursorHidden, setCursorHidden] = useLocalStorage(
    'as-cursor-hidden',
    false
  );
  const [currentEvent, setCurrentEvent] = useLocalStorage<Event | null>(
    'as-current-event',
    null
  );
  const [canSendStatus, setCanSendStatus] = useState(false);

  const vaultChannel = useMemo(() => {
    const channel = supabase.channel(pourParams.safeScreenChannel);
    channel.subscribe((status) => {
      // Wait for successful connection
      if (status !== 'SUBSCRIBED') {
        setCanSendStatus(false);
        return;
      }
      setCanSendStatus(true);
    });
    return channel;
  }, [pourParams.safeScreenChannel]);

  const sendVaultStatusMessage = useCallback(
    (message: VaultMessage) => {
      if (!canSendStatus || !vaultChannel) {
        return;
      }
      vaultChannel.send({
        type: 'broadcast',
        event: 'screen',
        payload: message,
      });
    },
    [canSendStatus, vaultChannel]
  );

  const sendVaultMessagesMessage = useCallback(
    (message: MessagesMessage) => {
      if (!canSendStatus || !vaultChannel) {
        return;
      }
      vaultChannel.send({
        type: 'broadcast',
        event: 'messages',
        payload: message,
      });
    },
    [canSendStatus, vaultChannel]
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const vaultScreenParam = params.get('vault-screen');
    if (vaultScreenParam === '1') {
      setEnableVaultScreen(true);
    }
  }, []);

  const toggleCursorVisibility = () => {
    setCursorHidden((s) => !s);
  };

  useEffect(() => {
    if (cursorHidden == true) {
      document.documentElement.style.cursor = 'none';
      document.body.classList.add('hide-cursor');
    } else {
      document.documentElement.style.cursor = 'auto';
      document.body.classList.remove('hide-cursor');
    }
  }, [cursorHidden]);

  useEffect(() => {
    console.log({ hasConnection, hasConnectionError });
  }, [hasConnection, hasConnectionError]);

  useEffect(() => {
    // TODO: read connection ID
    // make connection request - here or inside ws provider?

    if (!hasConnection) {
    }
  }, []);

  useEffect(() => {
    console.log({ cupDetected });
  }, [cupDetected]);

  useEffect(() => {
    if (pourParams.minAttention === undefined) {
      setPourParams((p) => ({ ...p, minAttention: DEFAULT_MIN_ATTENTION }));
    }
  }, [pourParams.minAttention]);

  useEffect(() => {
    if (pourParams.maxAttention === undefined) {
      setPourParams((p) => ({ ...p, maxAttention: DEFAULT_MAX_ATTENTION }));
    }
  }, [pourParams.maxAttention]);

  const resetAttentionThresholds = () => {
    setPourParams((p) => ({
      ...p,
      minAttention: DEFAULT_MIN_ATTENTION,
      maxAttention: DEFAULT_MAX_ATTENTION,
    }));
  };

  const resetDropperThresholds = () => {
    setPourParams((p) => ({
      ...p,
      minDroppers: DEFAULT_MIN_DROPPERS,
      maxDroppers: DEFAULT_MAX_DROPPERS,
    }));
  };

  return (
    <GlobalStateContext.Provider
      value={{
        account,
        setAccount,
        currentEvent,
        setCurrentEvent,
        headsetList,
        setHeadsetList,
        currentMode,
        websocketUrl,
        setWebsocketUrl,
        skin,
        setSkin,
        defaultMode,
        setDefaultMode,
        defaultSkin,
        setDefaultSkin,
        hasRobotConnection: hasConnection,
        setCurrentMode,
        setConnectionStatus: setHasConnection,
        connectionStatus: hasConnection,
        setRobotDemoMode,
        robotDemoMode,
        setSkipPreGamePersonalDetailEntry,
        skipPreGamePersonalDetailEntry,
        collectEmail,
        setCollectEmail,
        useSoftwareKeyboard,
        setUseSoftwareKeyboard,
        setPreGamePersonalDetailEntry,
        preGamePersonalDetailEntry,
        setPostGamePersonalDetailEntry,
        postGamePersonalDetailEntry,
        cupDetected,
        setCupDetected,
        hasConnectionError,
        setHasConnectionError,
        pourParams,
        setPourParams,
        vaultScreenParams,
        setVaultScreenParams,
        endGameDialogs,
        setEndGameDialogs,
        connectionStatusData,
        setConnectionStatusData,
        toggleCursorVisibility,
        localHeadsetConnected,
        setLocalHeadsetConnected,
        localHeadsetFocus,
        setLocalHeadsetFocus,
        resetAttentionThresholds,
        resetDropperThresholds,
        interruptPour,
        setInterruptPour,
        gameMode,
        setGameMode,
        mockMindControl,
        setMockMindControl,
        enableVaultScreen,
        setEnableVaultScreen,
        sendVaultStatusMessage,
        sendVaultMessagesMessage,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

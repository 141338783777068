import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { supabase } from '../../lib/db/client';
import { AppState, useGlobalState } from '../../lib/globalStateProvider';

type ScreenPayload = {
  type: 'broadcast';
  event: string;
  payload: VaultMessage;
};

type MessagesPayload = {
  type: 'broadcast';
  event: string;
  payload: MessagesMessage;
};

const PADDING = 50;
const SPEED_DIVISOR = 200;

export type VaultMode = 'message' | 'name' | 'time' | 'score';

export type VaultMessage = {
  mode: VaultMode;
  name?: string;
  time?: number;
  elapsed?: number;
  score?: number;
};

export type MessagesMessage = {
  safeInitialMessage: string;
  safeLoseMessage: string;
  safeWinMessage: string;
  safeScreenColour: string;
  safeMessageAmount: number;
  safeMessageInterval: number;
};

function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
}

function formatMessage(
  message: string,
  secondsElapsed: number,
  messageAmount: number,
  messageInterval: number
): string {
  const totalThings = Math.round(
    (secondsElapsed / messageInterval) * messageAmount
  );
  return message.replace('{total}', String(totalThings));
}

const VaultScreen = (): JSX.Element => {
  const { pourParams, vaultScreenParams, setVaultScreenParams, currentMode } =
    useGlobalState();
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [name, setName] = useState('');
  const [score, setScore] = useState(1);
  const [width, setWidth] = useState('100vw');
  const [speed, setSpeed] = useState(10);
  const [animate, setAnimate] = useState(true);
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState<VaultMode>('message');
  const changeVisibility = useRef(true);

  useEffect(() => {
    if (currentMode === AppState.Home) {
      setMode('message');
      setName('');
    }
  }, [currentMode]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // Join a room/topic. Can be anything except for 'realtime'.
      const channelA = supabase.channel(pourParams.safeScreenChannel);

      // Simple function to log any messages we receive
      const messageReceived = (payload: VaultMessage) => {
        console.log(payload);
        setTimeRemaining(payload.time ?? 0);
        setTimeElapsed(payload.elapsed ?? 0);
        if (payload.mode === 'name') {
          setName(payload.name ?? '');
        }
        if (payload.mode === 'score') {
          setScore(payload.score ?? 0);
        }
        if (payload.mode === 'message') {
          setName('');
        }
        setMode(payload.mode);
        if (payload.mode !== 'time') {
          changeVisibility.current = true;
        }
      };

      const messagesReceived = (payload: MessagesMessage) => {
        setVaultScreenParams((p) =>
          p.safeInitialMessage !== payload.safeInitialMessage ||
          p.safeWinMessage !== payload.safeWinMessage ||
          p.safeLoseMessage !== payload.safeLoseMessage ||
          p.safeMessageAmount !== payload.safeMessageAmount ||
          p.safeMessageInterval !== payload.safeMessageInterval ||
          p.safeScreenColour !== payload.safeScreenColour
            ? {
                ...p,
                ...payload,
              }
            : p
        );
      };

      // Subscribe to the Channel
      channelA
        .on('broadcast', { event: 'screen' }, (payload: ScreenPayload) =>
          messageReceived(payload.payload)
        )
        .on('broadcast', { event: 'messages' }, (payload: MessagesPayload) =>
          messagesReceived(payload.payload)
        )
        .subscribe();
    }
    return () => {
      mounted = false;
    };
  }, [pourParams.safeScreenChannel]);

  const message = useMemo(
    () =>
      mode === 'message'
        ? vaultScreenParams.safeInitialMessage
        : mode === 'time'
        ? formatTime(timeRemaining)
        : mode === 'name'
        ? name === ''
          ? vaultScreenParams.safeInitialMessage
          : name.trim()
        : mode === 'score'
        ? score === 1
          ? formatMessage(
              vaultScreenParams.safeWinMessage,
              timeElapsed,
              vaultScreenParams.safeMessageAmount,
              vaultScreenParams.safeMessageInterval
            )
          : formatMessage(
              vaultScreenParams.safeLoseMessage,
              timeElapsed,
              vaultScreenParams.safeMessageAmount,
              vaultScreenParams.safeMessageInterval
            )
        : vaultScreenParams.safeInitialMessage,
    [
      mode,
      vaultScreenParams.safeInitialMessage,
      timeRemaining,
      timeElapsed,
      name,
      vaultScreenParams.safeWinMessage,
      vaultScreenParams.safeLoseMessage,
      vaultScreenParams.safeMessageAmount,
      vaultScreenParams.safeMessageInterval,
    ]
  );

  useEffect(() => {
    if (changeVisibility.current) {
      setVisible(false);
    }
  }, [message]);

  useEffect(() => {
    if (!divRef.current) {
      return;
    }
    const observer = new ResizeObserver((entries) => {
      const messageWidth = entries[0].contentRect.width;
      setWidth(`${messageWidth}px`);
      setSpeed((messageWidth + window.innerWidth) / SPEED_DIVISOR);
      setAnimate(messageWidth + PADDING > window.innerWidth);
      setVisible(true);
      changeVisibility.current = mode !== 'time';
    });
    observer.observe(divRef.current);
    return () => {
      divRef.current && observer.unobserve(divRef.current);
    };
  }, [mode]);

  return (
    <VaultScreenContainer
      animate={animate}
      visible={visible}
      width={width}
      speed={speed}
      colour={vaultScreenParams.safeScreenColour}
    >
      <div ref={divRef}>{message}</div>
    </VaultScreenContainer>
  );
};

export default VaultScreen;

const scrollAnimation = (from = '100vw', to = '-100vw') => keyframes`
  0% {
    transform: translateX(${from});
  }
  100% {
    transform: translateX(${to});
  }
`;

interface VaultScreenContainerProps {
  animate: boolean;
  visible: boolean;
  width: string;
  speed: number;
  colour?: string;
}

const VaultScreenContainer = styled.div<VaultScreenContainerProps>`
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  color: ${(p) => p.colour ?? 'white'};
  background: #000;
  z-index: 99999;
  font-size: 60vh;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.animate ? 'flex-start' : 'center')};
  overflow: hidden;

  div {
    text-align: center;
    font-family: Teletactile, sans-serif;
    font-weight: 400;
    display: inline-block;
    width: auto;
    overflow: visible;
    white-space: nowrap;
    visibility: ${(p) => (p.visible ? 'visible' : 'hidden')};
    padding: 0;
    ${(p) =>
      p.animate &&
      css`
        transform-origin: left center;
        transform: translateX(100vw);
        animation: ${scrollAnimation('100vw', `-${p.width}`)} ${p.speed}s linear
          infinite;
      `}
  }
`;

import { GameMode, useGlobalState } from './globalStateProvider';
import { UsePourController } from './types/pourController';
import useBeerController from './useBeerController';
import useCoffeeController from './useCoffeeController';
import useSwingController from './useSwingController';

const usePourController = (
  onCompleteCallback?: () => void,
  usePractice = true
): UsePourController => {
  const { gameMode } = useGlobalState();
  const beerController = useBeerController(onCompleteCallback, usePractice);
  const coffeeController = useCoffeeController(onCompleteCallback, usePractice);
  const swingController = useSwingController(onCompleteCallback, usePractice);

  if (gameMode === GameMode.Swing) {
    return swingController;
  }
  if (gameMode === GameMode.Coffee) {
    return coffeeController;
  }
  return beerController;
};

export default usePourController;

// Handy hook from Dan Abramov: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

import { useEffect, useRef } from 'react';

function useInterval(
  callback: () => void,
  delay: number | null,
  onUnmount?: () => void
): void {
  const savedCallback = useRef(callback);
  const savedOnUmount = useRef(onUnmount);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedOnUmount.current = onUnmount;
  }, [onUnmount]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => {
      clearInterval(id);
    };
  }, [delay]);
}

export default useInterval;

import {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { MuseClient, zipSamples } from 'muse-js';
import { filter } from 'rxjs';
import {
  bandpassFilter,
  epoch,
  fft,
  powerByBand,
  average,
  addSignalQuality,
} from '@neurosity/pipes';
import {
  PourParams,
  useGlobalState,
  DEFAULT_MIN_ATTENTION,
  DEFAULT_MAX_ATTENTION,
  ATTENTION_EXPONENT,
  MAX_ATTENTION_MULTIPLIER,
} from './globalStateProvider';
import { lerp } from './utils';

const SCALING_FACTOR = 100;
const SMOOTH_FOCUS = true;

const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max);

const scaleFocus = (focus: number, min: number, max: number): number => {
  // return focus;
  const x = (focus - min) / (max - min);
  return -1 * (1 / Math.exp(2 * x)) + 1.1;
};

function randFloat(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export enum HeadsetQuality {
  BAD,
  MEDIUM,
  GOOD,
}

interface LocalMindControlState {
  connectToHeadset: () => void;
  disconnectFromHeadset: () => void;
  headsetBattery: number;
  headsetName: string;
  headsetOn: boolean;
  headsetQuality: HeadsetQuality;
  acceleration: number;
  setCalibrateMode: (b: boolean) => void;
  calibrateMode: boolean;
  setCalibrateDuration: (n: number) => void;
}

export const LocalMindControlContext = createContext(
  {} as LocalMindControlState
);

export const useLocalMindControl = (): LocalMindControlState =>
  useContext(LocalMindControlContext);

export const LocalMindControlProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const {
    setLocalHeadsetConnected,
    localHeadsetConnected,
    setLocalHeadsetFocus,
    pourParams,
    setPourParams,
  } = useGlobalState();
  const headsetClient = useRef<MuseClient>();
  const [headsetBattery, setHeadsetBattery] = useState<number>(0);
  const [headsetName, setHeadsetName] = useState<string>('');
  const [focus, setFocus] = useState<number>(0);
  const [headsetOn, setHeadsetOn] = useState<boolean>(false);
  const [acceleration, setAcceleration] = useState<number>(0);
  const [rawAttention, setRawAttention] = useState<number>(0);
  const [calibrateMode, setCalibrateMode] = useState<boolean>(false);
  const [calibrateStarted, setCalibrateStarted] = useState<boolean>(false);
  const [calibrateDuration, setCalibrateDuration] = useState<number>(10000);
  const [headsetQuality, setHeadsetQuality] = useState<HeadsetQuality>(
    HeadsetQuality.BAD
  );

  useEffect(() => {
    if (calibrateMode == true) {
      setPourParams((p: PourParams) => ({
        ...p,
        minAttention: 1,
        maxAttention: 0,
      }));
      setCalibrateStarted(true);
      const timeout = setTimeout(() => {
        setCalibrateMode(false);
        setCalibrateStarted(false);
      }, calibrateDuration);

      return () => clearTimeout(timeout);
    } else {
      setCalibrateStarted(false);
    }
  }, [calibrateMode, calibrateDuration]);

  const disconnectFromHeadset = useCallback(() => {
    if (headsetClient.current) {
      headsetClient.current.disconnect();
      setLocalHeadsetConnected(false);
    }
  }, []);

  useEffect(() => {
    if (!localHeadsetConnected) {
      setHeadsetName('');
      setFocus(0);
    }
  }, [localHeadsetConnected]);

  const connectToHeadset = useCallback(async () => {
    headsetClient.current = new MuseClient();
    headsetClient.current.enablePpg = true;
    await headsetClient.current.connect();
    await headsetClient.current.start();
    setLocalHeadsetConnected(
      headsetClient.current.connectionStatus.closed == false
    );
    setHeadsetName(headsetClient.current?.deviceName ?? '');
    headsetClient.current.connectionStatus.subscribe((value) => {
      setLocalHeadsetConnected(value);
    });
    // const info = await headsetClient.current.deviceInfo();
    headsetClient.current.telemetryData.subscribe((value) => {
      // console.log('Telemetry: ', value);
      setHeadsetBattery(value.batteryLevel);
    });
    zipSamples(headsetClient.current.eegReadings)
      .pipe(
        // eslint-disable-next-line
        // @ts-ignore
        bandpassFilter({
          cutoffFrequencies: [2, 50],
          nbChannels: 4,
        }),
        epoch({ duration: 256, samplingRate: 256, interval: 100 }),
        addSignalQuality(),
        filter((value) => {
          let badQualCount = 0;
          for (const qualKey of Object.keys(value.info.signalQuality)) {
            const qual = value.info.signalQuality[qualKey];
            if (!isNaN(qual) && qual > 200) {
              badQualCount += 1;
            }
          }
          setHeadsetOn(badQualCount < 2);
          setHeadsetQuality(
            badQualCount < 2
              ? HeadsetQuality.GOOD
              : badQualCount < 3
              ? HeadsetQuality.MEDIUM
              : HeadsetQuality.BAD
          );
          return true;
        }),
        fft({ bins: 256 }),
        powerByBand()
      )
      .subscribe((value) => {
        const { delta, theta, alpha, beta, gamma } = value;

        const attention =
          Math.pow(average(gamma), ATTENTION_EXPONENT) /
          (Math.pow(average(gamma), ATTENTION_EXPONENT) +
            Math.pow(average(theta), ATTENTION_EXPONENT) +
            Math.pow(average(delta), ATTENTION_EXPONENT) +
            Math.pow(average(alpha), ATTENTION_EXPONENT) +
            Math.pow(average(beta), ATTENTION_EXPONENT));

        setRawAttention(attention);
      });

    headsetClient.current.gyroscopeData.subscribe((data) => {
      const start = Math.sqrt(
        Math.pow(data.samples[0].x, 2) +
          Math.pow(data.samples[0].y, 2) +
          Math.pow(data.samples[0].z, 2)
      );
      const end = Math.sqrt(
        Math.pow(data.samples[data.samples.length - 1].x, 2) +
          Math.pow(data.samples[data.samples.length - 1].y, 2) +
          Math.pow(data.samples[data.samples.length - 1].z, 2)
      );
      setAcceleration(1 - clamp((end + start) / 400, 0, 0.5));
    });
  }, []);

  useEffect(() => {
    if (calibrateMode && calibrateStarted) {
      const possibleMaxAttention = rawAttention * MAX_ATTENTION_MULTIPLIER;
      setPourParams((p: PourParams) => ({
        ...p,
        minAttention:
          p.minAttention < rawAttention ? p.minAttention : rawAttention,
        maxAttention:
          p.maxAttention > possibleMaxAttention
            ? p.maxAttention
            : possibleMaxAttention,
      }));
      setFocus(
        scaleFocus(rawAttention, DEFAULT_MIN_ATTENTION, DEFAULT_MAX_ATTENTION)
      );
    }
  }, [rawAttention, calibrateMode, calibrateStarted]);

  useEffect(() => {
    if (!calibrateMode) {
      setFocus(
        scaleFocus(
          rawAttention,
          pourParams.minAttention ?? DEFAULT_MIN_ATTENTION,
          pourParams.maxAttention
        )
      );
    }
  }, [
    rawAttention,
    calibrateMode,
    pourParams.minAttention,
    pourParams.maxAttention,
  ]);

  useEffect(() => {
    const normalisedFocus = (focus ?? 0) * SCALING_FACTOR * acceleration;
    setLocalHeadsetFocus((curr) =>
      headsetOn
        ? SMOOTH_FOCUS
          ? clamp(lerp(curr, normalisedFocus + randFloat(-3, 3), 0.1), 0, 100)
          : clamp(normalisedFocus, 0, 100)
        : 0
    );
  }, [headsetOn, focus, acceleration]);

  return (
    <LocalMindControlContext.Provider
      value={{
        connectToHeadset,
        disconnectFromHeadset,
        headsetBattery,
        headsetName,
        headsetOn,
        headsetQuality,
        acceleration,
        setCalibrateMode,
        calibrateMode,
        setCalibrateDuration,
      }}
    >
      {children}
    </LocalMindControlContext.Provider>
  );
};

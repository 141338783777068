import { useCallback, useRef } from 'react';

import { useWebSocket } from './sockets/ws-provider';

interface UseServo {
  setAngles: (tap: number, glass: number) => void;
  sendAnglesToServo: (
    tagAngle?: number | null,
    glassAngle?: number | null
  ) => void;
  setAndSendGlassAngle: (angle: number) => void;
  setAndSendTapAngle: (angle: number) => void;
}

interface ServoAngles {
  glass: number;
  tap: number;
}

const useServo = (
  initialGlassAngle: number,
  initialTapAngle: number
): UseServo => {
  const { sendWsMessage } = useWebSocket();
  // TODO: need to get initial angles from state
  const servoAngles = useRef<ServoAngles>({
    glass: initialGlassAngle,
    tap: initialTapAngle,
  });

  const sendAnglesToServo = useCallback(
    (tagAngle: number | null = null, glassAngle: number | null = null) => {
      sendWsMessage({
        type: 'update_angle',
        data: {
          tap_angle: tagAngle ?? servoAngles.current.tap,
          glass_angle: glassAngle ?? servoAngles.current.glass,
        },
      });
    },
    [sendWsMessage]
  );

  const setAngles = useCallback((tap: number, glass: number) => {
    servoAngles.current.tap = tap;
    servoAngles.current.glass = glass;
  }, []);

  const setAndSendGlassAngle = useCallback(
    (glassAngle: number) => {
      console.log('Update glass angle: ', glassAngle);
      setAngles(servoAngles.current.tap, glassAngle);
      sendWsMessage({
        type: 'update_angle',
        data: {
          glass_angle: glassAngle,
        },
      });
    },
    [sendWsMessage, setAngles]
  );

  const setAndSendTapAngle = useCallback(
    (tapAngle: number) => {
      console.log('Update tap angle: ', tapAngle);
      setAngles(tapAngle, servoAngles.current.glass);
      sendWsMessage({
        type: 'update_angle',
        data: {
          tap_angle: tapAngle,
        },
      });
    },
    [sendWsMessage, setAngles]
  );

  return {
    setAngles,
    sendAnglesToServo,
    setAndSendGlassAngle,
    setAndSendTapAngle,
  };
};

export default useServo;

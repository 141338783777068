import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { Session } from '@supabase/supabase-js';

import appTheme from './styles/theme';
import GlobalStyle from './styles/globalStyle';

import Body from './components/pages/Body';
import Home from './components/pages/Home';
import Config from './components/pages/Config';
import MindMode from './components/pages/MindMode';
import Login from './components/pages/Login';
import Loader from './components/Loader';
import VaultScreen from './components/pages/VaultScreen';

import { WebSocketProvider } from './lib/sockets/ws-provider';
import {
  AppState,
  GlobalStateContext,
  GlobalStateProvider,
} from './lib/globalStateProvider';
import { LocalMindControlProvider } from './lib/localMindControlProvider';
import { QueryProvider } from './lib/db/QueryProvider';
import { supabase } from './lib/db/client';
import ConnectionErrorOverlay from './components/ConnectionErrorOverlay';
// import useNetwork from './lib/useNetwork';
import ConnectionStatusDisplay from './components/ConnectionStatusDisplay';
import EventSelector from './components/EventSelector';
import RaceMode from './components/pages/RaceMode';
import Button from './components/Button';
import { PersonalDetailsProvider } from './lib/usePersonalDetails';
import SafeMode from './components/pages/SafeMode';

function App(): JSX.Element {
  const [session, setSession] = useState<Session | null>(null);
  // const isOnline = useNetwork();

  useEffect(() => {
    async function fetchSession() {
      const { data } = await supabase.auth.getSession();
      setSession(data.session);

      if (data.session) {
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (user === null) {
          Sentry.setContext('user', {
            id: data.session?.user?.id,
            expiresAt: data.session?.expires_at,
            expiresIn: data.session?.expires_in,
          });
          Sentry.captureMessage('User not found', 'error');
          await supabase.auth.signOut();
          setSession(null);
        }
      }
    }

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    void fetchSession();
  }, []);

  return (
    <>
      <Router>
        <GlobalStateProvider>
          <ConnectedApp session={session} />
        </GlobalStateProvider>
      </Router>
    </>
  );
}

export default App;

const Container = styled.div`
  /* min-height: calc(100vh - ${(props) =>
    props.theme.dimensions.navHeight}); */
  min-height: 100%;
  height: 100%;
`;

const ConnectedApp = ({
  session,
}: {
  session: Session | null;
}): JSX.Element => {
  const { websocketUrl } = useContext(GlobalStateContext);

  if (websocketUrl === undefined) {
    throw new Error('No websocket URL defined');
  }

  return (
    <PersonalDetailsProvider>
      <QueryProvider>
        <WebSocketProvider url={websocketUrl}>
          <LocalMindControlProvider>
            <ThemeProvider theme={appTheme}>
              <GlobalStyle />

              {/* removed last minute before event that had wifi issues */}
              {/* {!isOnline ? (
                <div
                  style={{
                    display: 'grid',
                    placeContent: 'center',
                    height: '100%',
                  }}
                >
                  <h1 style={{ textAlign: 'center' }}>
                    Can&apos;t connect to the internet
                  </h1>
                  <p style={{ textAlign: 'center' }}>
                    Please check your internet connection to continue
                  </p>
                </div>
              ) : (
                <WrappedApp session={session} />
              )} */}
              <WrappedApp session={session} />
              <Toaster
                toastOptions={{
                  style: {
                    fontFamily: 'Bevellier, Inter, sans-serif',
                  },
                }}
              />
            </ThemeProvider>
          </LocalMindControlProvider>
        </WebSocketProvider>
      </QueryProvider>
    </PersonalDetailsProvider>
  );
};

const WrappedApp = ({ session }: { session: Session | null }): JSX.Element => {
  const {
    hasRobotConnection,
    robotDemoMode,
    setRobotDemoMode,
    currentMode,
    skin,
    hasConnectionError,
    setAccount,
    currentEvent,
    enableVaultScreen,
  } = useContext(GlobalStateContext);

  const setUserAccount = () => {
    if (session && session.user) {
      setAccount({
        id: session.user?.id,
        username: 'strangethoughts',
        connection_id: 'd34i-ba8n',
      });
    }
  };

  useEffect(() => {
    if (location.hostname === 'localhost') {
      document.body.oncontextmenu = null;
    }
  }, []);

  useEffect(() => {
    if (session?.user) {
      setUserAccount();
    }
  }, [session]);

  useEffect(() => {
    console.log('inside app.tsx, connection: ', hasRobotConnection);
  }, [hasRobotConnection]);

  if (!session || !session.user) {
    return (
      <Container>
        <Login />
      </Container>
    );
  }

  if (!enableVaultScreen && hasConnectionError && !robotDemoMode) {
    return <ConnectionErrorOverlay setRobotDemoMode={setRobotDemoMode} />;
  }

  if (!enableVaultScreen && !hasRobotConnection && !robotDemoMode) {
    return (
      <Container>
        <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <div style={{ width: '200px', height: '200px' }}>
              <Loader />
            </div>
            <p style={{ fontSize: '1.2rem' }}>Connecting to hardware...</p>
            <Button
              style={{ maxWidth: '256px' }}
              onClick={() => setRobotDemoMode(true)}
            >
              Demo Mode
            </Button>
          </div>
        </div>
      </Container>
    );
  }

  if (currentEvent === null || currentEvent === undefined) {
    return <EventSelector accountId={session.user.id} />;
  }

  if (enableVaultScreen) {
    return <VaultScreen key="vault-screen" />;
  }

  return (
    <>
      <Container>
        <AnimatePresence exitBeforeEnter>
          {currentMode == AppState.Body ? (
            <Body key="body" accountId={session.user.id} />
          ) : currentMode == AppState.Mind ? (
            <MindMode key="mind" accountId={session.user.id} />
          ) : currentMode == AppState.Race ? (
            <RaceMode key="race" skin={skin} accountId={session.user.id} />
          ) : currentMode == AppState.Safe ? (
            <SafeMode key="safe" accountId={session.user.id} />
          ) : currentMode == AppState.Config ? (
            <Config />
          ) : (
            <Home key="home" />
          )}
        </AnimatePresence>
        <ConnectionStatusDisplay />
      </Container>
    </>
  );
};

import styled from 'styled-components';

import { GameMode } from '../lib/types/game-types';

import Button, { ButtonContainer } from './Button';
import BackIcon from './icons/BackIcon';
import { Dialog, FullPageContainer } from './Layout';
import { usePersonalDetails } from '../lib/usePersonalDetails';
import {
  PersonalDetailEntry,
  useGlobalState,
} from '../lib/globalStateProvider';
import PersonalDetails from './PersonalDetails';
import { useState } from 'react';

interface PreGameDialogProps {
  callback: () => void;
  children?: React.ReactChild;
  isLoading?: boolean;
  mode: GameMode;
  exit: () => void;
}

export const PreGameDialog = ({
  callback,
  children,
  isLoading = false,
  mode,
  exit,
}: PreGameDialogProps): JSX.Element => {
  const { preGamePersonalDetailEntry, skipPreGamePersonalDetailEntry } =
    useGlobalState();
  const { confirmed } = usePersonalDetails();
  const [skipped, setSkipped] = useState(false);

  const onSkip = () => setSkipped(true);

  return (
    <FullPageContainer>
      <Dialog>
        <ButtonContainer style={{ alignSelf: 'flex-start' }} onClick={exit}>
          <BackIcon />
        </ButtonContainer>
        {preGamePersonalDetailEntry === PersonalDetailEntry.Form &&
        !skipped &&
        !confirmed ? (
          <PersonalDetails
            isSafeMode={mode === 'Safe'}
            skippable={skipPreGamePersonalDetailEntry}
            onSkip={onSkip}
          />
        ) : (
          <>
            <Instructions mode={mode} />
            <Button disabled={isLoading} onClick={callback}>
              {isLoading ? 'loading...' : 'start'}
            </Button>
            {children}
          </>
        )}
      </Dialog>
    </FullPageContainer>
  );
};

export default PreGameDialog;

const Instructions = ({ mode }: { mode: GameMode }): JSX.Element => {
  const { postGamePersonalDetailEntry } = useGlobalState();
  const { confirmed } = usePersonalDetails();
  if (mode === 'Mind' || mode === 'Safe') {
    return (
      <Wrapper>
        <img
          style={{
            maxHeight: '200px',
            minHeight: '200px',
            alignSelf: 'center',
          }}
          src="/images/headset.png"
          alt="Image of Mindwave Headset"
        />
        <p>
          Put on the headset, ensuring the power is turned on and the connectors
          are touching your ear and forehead
        </p>
        <p>The headset may take a few moments to find a signal</p>
        <p>
          Once the game begins, focus on the centre of the screen and increase
          your concentration to control the tap
        </p>
        {!confirmed &&
          postGamePersonalDetailEntry === PersonalDetailEntry.Form && (
            <p>
              At the end, enter your name to claim your score and enter the
              leaderboard
            </p>
          )}
        {!confirmed &&
          postGamePersonalDetailEntry === PersonalDetailEntry.QrCode && (
            <p>
              At the end, scan the QR code to claim your score and enter the
              leaderboard
            </p>
          )}
      </Wrapper>
    );
  } else if (mode === 'Body') {
    return (
      <Wrapper>
        <img
          style={{ maxHeight: '120px', alignSelf: 'center' }}
          src="/images/catchers.png"
          alt="Image of Pink and Blue catchers"
        />
        <p>This game uses the position of your body to control the flow</p>
        <p>
          Your right hand controls the pink catcher, your left hand the blue
        </p>
        <p>
          Coloured droplets will shoot out from the middle, catch them as
          quickly as you can, make sure you use the correct hand!
        </p>
        <p>
          At the end, scan the QR code to claim your score and enter the
          leaderboard
        </p>
      </Wrapper>
    );
  }
  return <></>;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: -48px;
`;

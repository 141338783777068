import { useCallback, useMemo, useRef, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { useGlobalState } from './globalStateProvider';
import { mapRange } from './utils';
import { UsePourController } from './types/pourController';

import useInterval from './useInterval';
import useServo from './useServo';

const useSwingController = (
  onCompleteCallback?: () => void,
  usePractice = true
): UsePourController => {
  const { pourParams } = useGlobalState();
  const coffeeMidAngle =
    (pourParams.coffeeHighAngle - pourParams.coffeeLowAngle) / 2 +
    pourParams.coffeeLowAngle;
  const tapSetOpen = useRef<boolean>(false);
  const { setAngles, sendAnglesToServo } = useServo(
    coffeeMidAngle,
    pourParams.tapOffAngle
  );
  const [hasFinished, setHasFinished] = useState(false);
  const gameTime = new Date();
  const pourTime = new Date();
  gameTime.setSeconds(
    gameTime.getSeconds() +
      pourParams.maxGameDurationSeconds +
      (usePractice ? pourParams.gamePracticeDuration : 0)
  );
  pourTime.setSeconds(
    pourTime.getSeconds() +
      pourParams.pourDurationSeconds +
      (usePractice ? pourParams.gamePracticeDuration : 0)
  );

  const onComplete = () => {
    setHasFinished(true);

    if (onCompleteCallback) {
      onCompleteCallback();
    }

    sendAnglesToServo(pourParams.tapOffAngle, coffeeMidAngle);
  };

  const onStart = (initialTap?: number, initialGlass?: number) => {
    setHasFinished(false);
    tapSetOpen.current = false;
    gameTime.setSeconds(
      gameTime.getSeconds() +
        pourParams.maxGameDurationSeconds +
        (usePractice ? pourParams.gamePracticeDuration : 0)
    );
    pourTime.setSeconds(
      pourTime.getSeconds() +
        pourParams.pourDurationSeconds +
        (usePractice ? pourParams.gamePracticeDuration : 0)
    );
    setAngles(
      initialTap ?? pourParams.tapOffAngle,
      initialGlass ?? coffeeMidAngle
    );
    previousAngle.current = coffeeMidAngle;
    previousDirection.current = -1;
    currentScore.current = 0;

    start();
    startPour();
  };

  const stopPour = () => {
    pause();
    pausePour();
    sendAnglesToServo(pourParams.tapOffAngle, coffeeMidAngle);
  };

  const {
    start,
    seconds: secondsRemaining,
    isRunning,
    pause,
  } = useTimer({
    expiryTimestamp: gameTime,
    onExpire: onComplete,
    autoStart: false,
  });

  const {
    start: startPour,
    seconds: pourSecondsRemaining,
    isRunning: isPourRunning,
    pause: pausePour,
    resume: resumePour,
  } = useTimer({
    expiryTimestamp: pourTime,
    onExpire: onComplete,
    autoStart: false,
  });

  const previousDirection = useRef(-1);
  const previousAngle = useRef(coffeeMidAngle);
  const currentScore = useRef(0);
  const previousTime = useRef(-1);

  const isInPractice = useMemo(
    () => secondsRemaining - pourParams.maxGameDurationSeconds >= 0,
    [secondsRemaining, pourParams.maxGameDurationSeconds]
  );

  useInterval(
    () => {
      if (isInPractice && !pourParams.practiceGlassMove) {
        // console.log('in practice, dont set servos');
        return;
      }

      if (previousTime.current != secondsRemaining) {
        console.log(
          'secondsRemaining: %d, pourSecondsRemaining: %d, isPourRunning: %s',
          secondsRemaining,
          pourSecondsRemaining,
          isPourRunning ? 'true' : 'false'
        );
        if (
          secondsRemaining <= 3 ||
          (isPourRunning && pourSecondsRemaining <= 3)
        ) {
          // ease back tap and glass for end of pour
          sendAnglesToServo(null, pourParams.glassWorstAngle);

          console.log(
            'g %d, t %d',
            pourParams.glassWorstAngle,
            pourParams.tapOnAngle
          );
        } else {
          const direction = previousDirection.current * -1;
          const angleRange =
            pourParams.coffeeHighAngle - pourParams.coffeeLowAngle;
          const scoreDifferenceMax = angleRange / 2;

          const scoreDifference =
            mapRange(currentScore.current, 100.0, 0, 0, scoreDifferenceMax) *
            direction;

          const targetAngle = coffeeMidAngle + scoreDifference;
          const angleDifference = Math.abs(previousAngle.current - targetAngle);

          if (angleDifference > pourParams.coffeeMinimumSwingAngle) {
            // const timeToAngle =
            //   (pourParams.coffeeFullRotationDurationMs / angleRange) *
            //   angleDifference;
            sendAnglesToServo(
              isInPractice ? pourParams.tapOffAngle : null,
              targetAngle
            );
            previousAngle.current = targetAngle;
            previousDirection.current = direction;
          }

          if (!isInPractice && !tapSetOpen.current) {
            pausePour();
          } else {
            resumePour();
          }
        }
        previousTime.current = secondsRemaining;
      }
      if (secondsRemaining <= 0 || pourSecondsRemaining <= 0) {
        onComplete();
      }
    },
    isRunning && !hasFinished ? 100 : null
  );

  const setAnglesForScore = useCallback(
    (score: number) => {
      currentScore.current = score;
      const tapAngle =
        score < pourParams.minTapFocus
          ? pourParams.tapOffAngle
          : pourParams.tapOnAngle;
      tapSetOpen.current = tapAngle === pourParams.tapOnAngle;
      const glassAngle = mapRange(
        score,
        0.0,
        100,
        pourParams.glassWorstAngle,
        pourParams.glassBestAngle
      );

      setAngles(tapAngle, glassAngle);
    },
    [
      setAngles,
      pourParams.tapOffAngle,
      pourParams.tapOnAngle,
      pourParams.glassWorstAngle,
      pourParams.glassBestAngle,
      pourParams.minTapFocus,
    ]
  );

  return {
    pourTime: pourParams.maxGameDurationSeconds,
    practiceTime: usePractice ? pourParams.gamePracticeDuration : 0,
    hasFinished,
    start: onStart,
    secondsRemaining,
    setAnglesForScore,
    isPouring: isPourRunning,
    stop: stopPour,
    inPractice: isInPractice,
  };
};

export default useSwingController;

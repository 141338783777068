import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { SimpleKeyboard } from 'react-simple-keyboard';

import {
  AppState,
  GameMode,
  PersonalDetailEntry,
  PourParams,
  SafeScoreAlgorithm,
  SafeVersion,
  useGlobalState,
  SAFE_COLOUR_OPTIONS,
  SafeColour,
  transformToRgb,
  transformSafeColourToRgbArray,
  VaultScreenParams,
  Dialog,
  initialEndGameDialogs,
} from '../../lib/globalStateProvider';
import { useWebSocket } from '../../lib/sockets/ws-provider';

import Button, { ButtonContainer } from '../Button';
import BackIcon from '../icons/BackIcon';
import { Container } from '../Layout';
import { useLocalMindControl } from '../../lib/localMindControlProvider';
import useModeChanger from '../../lib/useModeChanger';
import Keyboard from '../Keyboard';
import useServo from '../../lib/useServo';

type WebsocketUrl = {
  url: string;
  name: string;
};

const WEBSOCKET_URLS: WebsocketUrl[] = [
  {
    url: process.env.REACT_APP_WEBSOCKET_URL ?? 'ws://localhost:9092',
    name: 'Localhost',
  },
  ...Array.from(Array(16).keys()).map((i) => ({
    url: `wss://192.168.192.${i + 1}:9092`,
    name: `Arcade ${i + 1}`,
  })),
];

const sortDialogs = (dialogs: Dialog[]) =>
  dialogs
    .sort((a, b) => a.minScore - b.minScore)
    .map((dialog, index) =>
      index === 0
        ? {
            ...dialog,
            minScore: 0,
            maxScore: dialogs[index + 1].minScore - 1,
          }
        : index === dialogs.length - 1
        ? { ...dialog, maxScore: 10000 }
        : { ...dialog, maxScore: dialogs[index + 1].minScore - 1 }
    );

const Config = (): JSX.Element => {
  const {
    pourParams,
    setPourParams,
    toggleCursorVisibility,
    setCurrentEvent,
    setCurrentMode,
    websocketUrl,
    setWebsocketUrl,
    headsetList,
    localHeadsetConnected,
    localHeadsetFocus,
    resetAttentionThresholds,
    resetDropperThresholds,
    interruptPour,
    gameMode,
    setGameMode,
    mockMindControl,
    setMockMindControl,
    skipPreGamePersonalDetailEntry,
    setSkipPreGamePersonalDetailEntry,
    collectEmail,
    setCollectEmail,
    preGamePersonalDetailEntry,
    setPreGamePersonalDetailEntry,
    postGamePersonalDetailEntry,
    setPostGamePersonalDetailEntry,
    useSoftwareKeyboard,
    setUseSoftwareKeyboard,
    setEnableVaultScreen,
    sendVaultMessagesMessage,
    vaultScreenParams,
    setVaultScreenParams,
    endGameDialogs,
    setEndGameDialogs,
  } = useGlobalState();
  const {
    connectToHeadset,
    disconnectFromHeadset,
    headsetBattery,
    headsetName,
    headsetOn,
    acceleration,
    calibrateMode,
    setCalibrateMode,
  } = useLocalMindControl();
  const [newParams, setNewParams] = useState<PourParams>(pourParams);
  const [newVaultScreenParams, setNewVaultScreenParams] =
    useState<VaultScreenParams>(vaultScreenParams);
  const [newEndGameDialogs, setNewEndGameDialogs] =
    useState<Dialog[]>(endGameDialogs);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [currentFocusKey, setCurrentFocusKey] = useState<
    keyof PourParams | keyof VaultScreenParams
  >();
  const keyboard = useRef<SimpleKeyboard>();
  const { sendWsMessage } = useWebSocket();
  const { setAndSendGlassAngle, setAndSendTapAngle } = useServo(
    pourParams.glassWorstAngle,
    pourParams.tapOffAngle
  );
  const { changeMode } = useModeChanger();
  const resolvedWebsocketUrl = useMemo(
    () =>
      WEBSOCKET_URLS.map((u) => u.url).includes(websocketUrl)
        ? websocketUrl
        : 'custom',
    [websocketUrl]
  );

  const back = useCallback(() => changeMode(AppState.Home), [changeMode]);

  const onConnectClick = useCallback(() => {
    if (!localHeadsetConnected) {
      void connectToHeadset();
    } else {
      disconnectFromHeadset();
    }
  }, [localHeadsetConnected]);

  useEffect(() => {
    setNewParams((p) => ({ ...p, minAttention: pourParams.minAttention }));
  }, [pourParams.minAttention]);

  useEffect(() => {
    setNewParams((p) => ({ ...p, maxAttention: pourParams.maxAttention }));
  }, [pourParams.maxAttention]);

  useEffect(() => {
    sendVaultMessagesMessage({ ...vaultScreenParams });
  }, [vaultScreenParams]);

  useEffect(() => {
    sendWsMessage({
      type: 'safe_v2_reset',
      data: {
        defaultStageColour: transformToRgb(pourParams.safeDefaultColour),
        completedStageColour: transformToRgb(pourParams.safeCompletedColour),
        currentStageColour: transformToRgb(pourParams.safeInProgressColour),
        idleColours: transformSafeColourToRgbArray(
          pourParams.safeIdleSelection,
          pourParams.safeIdleCustomColour
        ),
        idleColoursInterval: pourParams.safeIdleInterval,
        completeColours: transformSafeColourToRgbArray(
          pourParams.safeCompleteSelection,
          pourParams.safeCompleteCustomColour
        ),
        completeColoursInterval: pourParams.safeCompleteInterval,
        animate: true,
      },
    });
  }, [pourParams]);

  useEffect(() => {
    if (
      useSoftwareKeyboard &&
      keyboardVisible &&
      keyboard.current !== undefined &&
      currentFocusKey !== undefined
    ) {
      let val;
      if (Object.prototype.hasOwnProperty.call(newParams, currentFocusKey)) {
        val = newParams[currentFocusKey as keyof PourParams];
      } else {
        val = newVaultScreenParams[currentFocusKey as keyof VaultScreenParams];
      }
      console.log('setInput', `${val}`);
      keyboard.current.setInput(`${val}`);
      console.log(keyboard.current.getInput(`pourParams${currentFocusKey}`));
    }
  }, [
    useSoftwareKeyboard,
    newParams,
    newVaultScreenParams,
    currentFocusKey,
    keyboardVisible,
  ]);

  const toggleInterruptPour = useCallback(() => {
    sendWsMessage({
      type: 'interrupt_pour',
      data: !interruptPour,
    });
  }, [interruptPour]);

  const toggleMockMindControl = useCallback(() => {
    setMockMindControl((m) => !m);
  }, [setMockMindControl]);

  return (
    <ConfigContainer>
      <div style={{ display: 'flex' }}>
        <ButtonContainer
          style={{ marginRight: '8px', paddingLeft: 0 }}
          onClick={back}
        >
          <BackIcon />
        </ButtonContainer>
        <Heading>Config</Heading>
      </div>

      <ConfigItem>
        <Label htmlFor="gameMode">Game Mode</Label>
        <div className="select">
          <select
            id="gameMode"
            name="gameMode"
            value={gameMode}
            onChange={(e) =>
              setGameMode(+e.target.value as unknown as GameMode)
            }
          >
            <option value={GameMode.Beer}>🍺 Beer</option>
            <option value={GameMode.Coffee}>☕️ Coffee</option>
            <option value={GameMode.Swing}>⚖️ Coffee Swing</option>
          </select>
        </div>
      </ConfigItem>

      <GroupHeading>Pour Controller</GroupHeading>
      <Item
        label="Pour Duration (s)"
        value={newParams.pourDurationSeconds}
        name="pourParamspourDurationSeconds"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('pourDurationSeconds');
        }}
        onBlur={(e) => {
          console.log(e.target.selectionStart);
          setKeyboardVisible(false);
        }}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, pourDurationSeconds: +val }))
        }
      />
      <Item
        label="Max Game Duration (s)"
        value={newParams.maxGameDurationSeconds}
        name="pourParamsmaxGameDurationSeconds"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('maxGameDurationSeconds');
        }}
        onBlur={(e) => {
          console.log(e.target.selectionStart);
          setKeyboardVisible(false);
        }}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, maxGameDurationSeconds: +val }))
        }
      />
      <Item
        label="Game practice time (s)"
        value={newParams.gamePracticeDuration}
        name="pourParamsgamePracticeDuration"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('gamePracticeDuration');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, gamePracticeDuration: +val }))
        }
      />
      <Item
        label={
          gameMode === GameMode.Beer
            ? 'Optimal Glass Angle (Beer)'
            : 'Worst Glass Angle (Coffee)'
        }
        value={newParams.glassBestAngle}
        name="pourParamsglassBestAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('glassBestAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, glassBestAngle: +val }))
        }
        onButtonPress={(v: number) => {
          setAndSendGlassAngle(v);
        }}
      />
      <Item
        label={
          gameMode === GameMode.Beer
            ? 'Worst Glass Angle (Beer)'
            : 'Optimal Glass Angle (Coffee)'
        }
        value={newParams.glassWorstAngle}
        name="pourParamsglassWorstAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('glassWorstAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, glassWorstAngle: +val }))
        }
        onButtonPress={(v: number) => {
          setAndSendGlassAngle(v);
        }}
      />
      <ConfigItem>
        <Label htmlFor="pourParamspracticeGlassMove">
          Move glass in practice?
        </Label>
        <input
          type="checkbox"
          id="pourParamspracticeGlassMove"
          name="pourParamspracticeGlassMove"
          checked={newParams.practiceGlassMove}
          onChange={(e) =>
            setNewParams((p) => ({ ...p, practiceGlassMove: e.target.checked }))
          }
        />
      </ConfigItem>
      <Item
        label="Tap On Angle"
        value={newParams.tapOnAngle}
        name="pourParamstapOnAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('tapOnAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, tapOnAngle: +val }))}
        onButtonPress={(v: number) => {
          setAndSendTapAngle(v);
        }}
      />
      <Item
        label="Tap Off Angle"
        value={newParams.tapOffAngle}
        name="pourParamstapOffAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('tapOffAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, tapOffAngle: +val }))}
        onButtonPress={(v: number) => {
          setAndSendTapAngle(v);
        }}
      />
      <Item
        label="Min Tap Focus"
        value={newParams.minTapFocus}
        name="pourParamsminTapFocus"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('minTapFocus');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, minTapFocus: +val }))}
      />
      <Item
        label="Min Focus"
        value={newParams.minFocus}
        name="pourParamsminFocus"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('minFocus');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, minFocus: +val }))}
      />
      <Item
        label="Min Score Mapping"
        value={newParams.minScoreMapping}
        name="pourParamsminScoreMapping"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('minScoreMapping');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, minScoreMapping: +val }))
        }
      />
      <Item
        label="Min Attention"
        value={newParams.minAttention}
        name="pourParamsminAttention"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('minAttention');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, minAttention: +val }))}
      />
      <Item
        label="Max Attention"
        value={newParams.maxAttention}
        name="pourParamsmaxAttention"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('maxAttention');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, maxAttention: +val }))}
        buttonLabel="Reset"
        onButtonPress={() => {
          setNewParams((p) => ({
            ...p,
            maxAttention: pourParams.maxAttention,
            minAttention: pourParams.minAttention,
          }));
          resetAttentionThresholds();
        }}
      />

      <GroupHeading>End Game Scores</GroupHeading>
      {newEndGameDialogs.map((dialog, i) => (
        <ConfigItem key={i}>
          <Input
            type="text"
            id={`endGameDialogRank${i}`}
            name={`endGameDialogRank${i}`}
            style={{ width: '200px', marginRight: '10px' }}
            value={dialog.rank}
            placeholder="Rank"
            onChange={(e) =>
              setNewEndGameDialogs((d) =>
                d.map((dialog, index) =>
                  index === i ? { ...dialog, rank: e.target.value } : dialog
                )
              )
            }
          />
          <Input
            type="text"
            id={`endGameDialogText${i}`}
            name={`endGameDialogText${i}`}
            style={{ marginRight: '10px' }}
            value={dialog.text}
            onChange={(e) =>
              setNewEndGameDialogs((d) =>
                d.map((dialog, index) =>
                  index === i ? { ...dialog, text: e.target.value } : dialog
                )
              )
            }
          />
          <Input
            type="number"
            id={`endGameDialogMin${i}`}
            name={`endGameDialogMin${i}`}
            style={{ width: '100px', marginRight: '10px' }}
            value={dialog.minScore}
            onChange={(e) =>
              setNewEndGameDialogs((d) =>
                d.map((dialog, index) =>
                  index === i
                    ? { ...dialog, minScore: +e.target.value }
                    : dialog
                )
              )
            }
            onBlur={() => setNewEndGameDialogs((d) => sortDialogs(d))}
          />
          <Input
            type="number"
            id={`endGameDialogMin${i}`}
            name={`endGameDialogMin${i}`}
            style={{
              color: 'gray',
              borderColor: 'gray',
              width: '100px',
              marginRight: '10px',
            }}
            value={dialog.maxScore}
            readOnly
            onChange={(e) =>
              setNewEndGameDialogs((d) =>
                d.map((dialog, index) =>
                  index === i
                    ? { ...dialog, maxScore: +e.target.value }
                    : dialog
                )
              )
            }
          />
          {newEndGameDialogs.length > 2 && (
            <Button
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                marginLeft: '10px',
              }}
              onClick={() =>
                setNewEndGameDialogs((d) =>
                  sortDialogs(d.filter((_, index) => index !== i))
                )
              }
            >
              x
            </Button>
          )}
        </ConfigItem>
      ))}
      <ConfigItem>
        {newEndGameDialogs.length < 4 && (
          <Button
            style={{ marginRight: '10px' }}
            onClick={() =>
              setNewEndGameDialogs((d) =>
                sortDialogs([
                  ...d,
                  {
                    rank: '',
                    text: '',
                    minScore:
                      d.reduce((p, c) => (c.minScore > p ? c.minScore : p), 0) +
                      1,
                    maxScore: 10000,
                  },
                ])
              )
            }
          >
            Add
          </Button>
        )}
        <Button
          onClick={() => setNewEndGameDialogs([...initialEndGameDialogs])}
        >
          Reset
        </Button>
      </ConfigItem>

      <GroupHeading>Body</GroupHeading>
      <Item
        label="Min Droppers (per second)"
        value={newParams.minDroppers}
        name="pourParamsminDroppers"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('minDroppers');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, minDroppers: +val }))}
      />
      <Item
        label="Max Droppers (per second)"
        value={newParams.maxDroppers}
        name="pourParamsmaxDroppers"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('maxDroppers');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, maxDroppers: +val }))}
        buttonLabel="Reset"
        onButtonPress={() => {
          setNewParams((p) => ({
            ...p,
            maxDroppers: pourParams.maxDroppers,
            minDroppers: pourParams.minDroppers,
          }));
          resetDropperThresholds();
        }}
      />

      <GroupHeading>⚖️ Coffee Swing</GroupHeading>
      <Item
        label="Min Glass Angle"
        value={newParams.coffeeLowAngle}
        name="pourParamscoffeeLowAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('coffeeLowAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, coffeeLowAngle: +val }))
        }
        onButtonPress={(v: number) => {
          setAndSendGlassAngle(v);
        }}
      />
      <Item
        label="Max Glass Angle"
        value={newParams.coffeeHighAngle}
        name="pourParamscoffeeHighAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('coffeeHighAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, coffeeHighAngle: +val }))
        }
        onButtonPress={(v: number) => {
          setAndSendGlassAngle(v);
        }}
      />
      <Item
        label="Minimum Swing Angle"
        value={newParams.coffeeMinimumSwingAngle}
        name="pourParamscoffeeMinimumSwingAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('coffeeMinimumSwingAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, coffeeMinimumSwingAngle: +val }))
        }
      />
      <Item
        label="Full rotation duration (ms)"
        value={newParams.coffeeFullRotationDurationMs}
        name="pourParamscoffeeFullRotationDurationMs"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('coffeeFullRotationDurationMs');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, coffeeFullRotationDurationMs: +val }))
        }
      />

      <GroupHeading>🔐 Safe</GroupHeading>
      <ConfigItem>
        <Label htmlFor="safeVersion">Game Version</Label>
        <div className="select">
          <select
            id="safeVersion"
            name="safeVersion"
            value={newParams.safeVersion}
            onChange={(e) =>
              setNewParams((p) => ({
                ...p,
                safeVersion: +e.target.value as unknown as SafeVersion,
              }))
            }
          >
            <option value={SafeVersion.V1}>Version 1</option>
            <option value={SafeVersion.V2}>Version 2</option>
          </select>
        </div>
        {newParams.safeVersion === SafeVersion.V2 && (
          <>
            <Button
              style={{ width: 'fit-content', marginLeft: '10px' }}
              onClick={() => {
                sendWsMessage({ type: 'safe_v2_open' });
              }}
            >
              Open Safe
            </Button>
            <Button
              style={{ width: 'fit-content', marginLeft: '10px' }}
              onClick={() => {
                setEnableVaultScreen(true);
              }}
            >
              Open Screen
            </Button>
          </>
        )}
      </ConfigItem>
      <Item
        label="Game Duration (s)"
        value={newParams.safeGameDuration}
        name="pourParamssafeGameDuration"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('safeGameDuration');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, safeGameDuration: +val }))
        }
      />
      <Item
        label="Score send interval (s)"
        value={newParams.safeScoreIntervalSeconds}
        name="pourParamssafeScoreIntervalSeconds"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('safeScoreIntervalSeconds');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, safeScoreIntervalSeconds: +val }))
        }
      />
      <Item
        label="Score sample size"
        value={newParams.safeScoreSampleSize}
        name="pourParamssafeScoreSampleSize"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('safeScoreSampleSize');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, safeScoreSampleSize: +val }))
        }
      />
      <ConfigItem>
        <Label htmlFor="safeScoreAlgorithm">Score algorithm</Label>
        <div className="select">
          <select
            id="safeScoreAlgorithm"
            name="safeScoreAlgorithm"
            value={newParams.safeScoreAlgorithm}
            onChange={(e) =>
              setNewParams((p) => ({
                ...p,
                safeScoreAlgorithm: +e.target
                  .value as unknown as SafeScoreAlgorithm,
              }))
            }
          >
            <option value={SafeScoreAlgorithm.Average}>Average</option>
            <option value={SafeScoreAlgorithm.Max}>Max</option>
            <option value={SafeScoreAlgorithm.Min}>Min</option>
            <option value={SafeScoreAlgorithm.AverageNoZero}>
              Average (No Zeros)
            </option>
          </select>
        </div>
      </ConfigItem>
      <Item
        label="Lock 1 Threshold"
        value={newParams.safeLockThreshold1}
        name="pourParamssafeLockThreshold1"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('safeLockThreshold1');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, safeLockThreshold1: +val }))
        }
      />
      {newParams.safeVersion === SafeVersion.V2 && (
        <Item
          label="Lock 1 Duration (s)"
          value={newParams.safeLockTime1}
          name="pourParamssafeLockTime1"
          onFocus={() => {
            setKeyboardVisible(true);
            setCurrentFocusKey('safeLockTime1');
          }}
          onBlur={() => setKeyboardVisible(false)}
          onChange={(val) =>
            setNewParams((p) => ({ ...p, safeLockTime1: +val }))
          }
        />
      )}
      <Item
        label="Lock 2 Threshold"
        value={newParams.safeLockThreshold2}
        name="pourParamssafeLockThreshold2"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('safeLockThreshold2');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, safeLockThreshold2: +val }))
        }
      />
      {newParams.safeVersion === SafeVersion.V2 && (
        <Item
          label="Lock 2 Duration (s)"
          value={newParams.safeLockTime2}
          name="pourParamssafeLockTime2"
          onFocus={() => {
            setKeyboardVisible(true);
            setCurrentFocusKey('safeLockTime2');
          }}
          onBlur={() => setKeyboardVisible(false)}
          onChange={(val) =>
            setNewParams((p) => ({ ...p, safeLockTime2: +val }))
          }
        />
      )}
      <Item
        label="Lock 3 Threshold"
        value={newParams.safeLockThreshold3}
        name="pourParamssafeLockThreshold3"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('safeLockThreshold3');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, safeLockThreshold3: +val }))
        }
      />
      {newParams.safeVersion === SafeVersion.V2 && (
        <>
          <Item
            label="Lock 3 Duration (s)"
            value={newParams.safeLockTime3}
            name="pourParamssafeLockTime3"
            onFocus={() => {
              setKeyboardVisible(true);
              setCurrentFocusKey('safeLockTime3');
            }}
            onBlur={() => setKeyboardVisible(false)}
            onChange={(val) =>
              setNewParams((p) => ({ ...p, safeLockTime3: +val }))
            }
          />
          <Item
            label="Dial rotation interval (s)"
            value={newParams.safeDialRotationInterval}
            name="pourParamssafeDialRotationInterval"
            onFocus={() => {
              setKeyboardVisible(true);
              setCurrentFocusKey('safeDialRotationInterval');
            }}
            onBlur={() => setKeyboardVisible(false)}
            onChange={(val) =>
              setNewParams((p) => ({ ...p, safeDialRotationInterval: +val }))
            }
          />
          <Item
            label="Dial rotation amount"
            value={newParams.safeDialRotationAmount}
            name="pourParamssafeDialRotationAmount"
            onFocus={() => {
              setKeyboardVisible(true);
              setCurrentFocusKey('safeDialRotationAmount');
            }}
            onBlur={() => setKeyboardVisible(false)}
            onChange={(val) =>
              setNewParams((p) => ({ ...p, safeDialRotationAmount: +val }))
            }
          />
          <ConfigItem key={'safeDefaultColour'}>
            <Label htmlFor={'safeDefaultColour'}>
              Safe Ring Default Colour
            </Label>
            <input
              type="color"
              id={'safeDefaultColour'}
              name={'safeDefaultColour'}
              value={newParams.safeDefaultColour}
              onChange={(e) =>
                setNewParams((p) => ({
                  ...p,
                  safeDefaultColour: e.target.value,
                }))
              }
            />
          </ConfigItem>
          <ConfigItem key={'safeInProgressColour'}>
            <Label htmlFor={'safeInProgressColour'}>
              Safe Ring In Progress Colour
            </Label>
            <input
              type="color"
              id={'safeInProgressColour'}
              name={'safeInProgressColour'}
              value={newParams.safeInProgressColour}
              onChange={(e) =>
                setNewParams((p) => ({
                  ...p,
                  safeInProgressColour: e.target.value,
                }))
              }
            />
          </ConfigItem>
          <ConfigItem key={'safeCompletedColour'}>
            <Label htmlFor={'safeCompletedColour'}>
              Safe Ring Completed Colour
            </Label>
            <input
              type="color"
              id={'safeCompletedColour'}
              name={'safeCompletedColour'}
              value={newParams.safeCompletedColour}
              onChange={(e) =>
                setNewParams((p) => ({
                  ...p,
                  safeCompletedColour: e.target.value,
                }))
              }
            />
          </ConfigItem>
          <ConfigItem>
            <Label htmlFor="safeIdleSelection">Idle Colours</Label>
            <div className="select">
              <select
                id="safeIdleSelection"
                name="safeIdleSelection"
                value={newParams.safeIdleSelection}
                onChange={(e) =>
                  setNewParams((p) => ({
                    ...p,
                    safeIdleSelection: e.target.value as unknown as SafeColour,
                  }))
                }
              >
                {Object.keys(SAFE_COLOUR_OPTIONS).map((colour) => (
                  <option key={colour} value={colour}>
                    {SAFE_COLOUR_OPTIONS[colour as SafeColour]}
                  </option>
                ))}
              </select>
            </div>
          </ConfigItem>
          {newParams.safeIdleSelection === 'custom' && (
            <ConfigItem key={'safeIdleCustomColour'}>
              <Label htmlFor={'safeIdleCustomColour'}>Idle Custom Colour</Label>
              <input
                type="color"
                id={'safeIdleCustomColour'}
                name={'safeIdleCustomColour'}
                value={newParams.safeIdleCustomColour}
                onChange={(e) =>
                  setNewParams((p) => ({
                    ...p,
                    safeIdleCustomColour: e.target.value,
                  }))
                }
              />
            </ConfigItem>
          )}
          <Item
            label="Idle colour change interval (s)"
            value={newParams.safeIdleInterval}
            name="pourParamssafeIdleInterval"
            onFocus={() => {
              setKeyboardVisible(true);
              setCurrentFocusKey('safeIdleInterval');
            }}
            onBlur={() => setKeyboardVisible(false)}
            onChange={(val) =>
              setNewParams((p) => ({ ...p, safeIdleInterval: +val }))
            }
          />
          <ConfigItem>
            <Label htmlFor="safeCompleteSelection">Game Win Colours</Label>
            <div className="select">
              <select
                id="safeCompleteSelection"
                name="safeCompleteSelection"
                value={newParams.safeCompleteSelection}
                onChange={(e) =>
                  setNewParams((p) => ({
                    ...p,
                    safeCompleteSelection: e.target
                      .value as unknown as SafeColour,
                  }))
                }
              >
                {Object.keys(SAFE_COLOUR_OPTIONS).map((colour) => (
                  <option key={colour} value={colour}>
                    {SAFE_COLOUR_OPTIONS[colour as SafeColour]}
                  </option>
                ))}
              </select>
            </div>
          </ConfigItem>
          {newParams.safeCompleteSelection === 'custom' && (
            <ConfigItem key={'safeCompleteCustomColour'}>
              <Label htmlFor={'safeCompleteCustomColour'}>
                Game Win Custom Colour
              </Label>
              <input
                type="color"
                id={'safeCompleteCustomColour'}
                name={'safeCompleteCustomColour'}
                value={newParams.safeCompleteCustomColour}
                onChange={(e) =>
                  setNewParams((p) => ({
                    ...p,
                    safeCompleteCustomColour: e.target.value,
                  }))
                }
              />
            </ConfigItem>
          )}
          <Item
            label="Game win colour change interval (s)"
            value={newParams.safeCompleteInterval}
            name="pourParamssafeCompleteInterval"
            onFocus={() => {
              setKeyboardVisible(true);
              setCurrentFocusKey('safeCompleteInterval');
            }}
            onBlur={() => setKeyboardVisible(false)}
            onChange={(val) =>
              setNewParams((p) => ({ ...p, safeCompleteInterval: +val }))
            }
          />
          <ConfigItem key={'safeScreenChannel'}>
            <Label htmlFor={'safeScreenChannel'}>Safe Screen Channel</Label>
            <Input
              type="text"
              id={'safeScreenChannel'}
              name={'safeScreenChannel'}
              value={newParams.safeScreenChannel}
              onChange={(e) =>
                setNewParams((p) => ({
                  ...p,
                  safeScreenChannel: e.target.value,
                }))
              }
            />
          </ConfigItem>
          <Tip>
            Change this if you have multiple MV2 events running at the same
            time. Make sure the browser running the screen and the browser
            running the game are on the same channel.
          </Tip>
          <ConfigItem key={'safeInitialMessage'}>
            <Label htmlFor={'safeInitialMessage'}>Safe Screen Message</Label>
            <Input
              type="text"
              id={'safeInitialMessage'}
              name={'safeInitialMessage'}
              value={newVaultScreenParams.safeInitialMessage}
              onChange={(e) =>
                setNewVaultScreenParams((p) => ({
                  ...p,
                  safeInitialMessage: e.target.value,
                }))
              }
            />
          </ConfigItem>
          <ConfigItem key={'safeWinMessage'}>
            <Label htmlFor={'safeWinMessage'}>Win Message</Label>
            <Input
              type="text"
              id={'safeWinMessage'}
              name={'safeWinMessage'}
              value={newVaultScreenParams.safeWinMessage}
              onChange={(e) =>
                setNewVaultScreenParams((p) => ({
                  ...p,
                  safeWinMessage: e.target.value,
                }))
              }
            />
          </ConfigItem>
          <ConfigItem key={'safeLoseMessage'}>
            <Label htmlFor={'safeLoseMessage'}>Lose Message</Label>
            <Input
              type="text"
              id={'safeLoseMessage'}
              name={'safeLoseMessage'}
              value={newVaultScreenParams.safeLoseMessage}
              onChange={(e) =>
                setNewVaultScreenParams((p) => ({
                  ...p,
                  safeLoseMessage: e.target.value,
                }))
              }
            />
          </ConfigItem>
          <Tip>
            Use {'{total}'} as a placeholder for the variable below. e.g.
            <br /> &quot;Arcade Strange has poured {'{total}'} pints in the time
            it took you to win!&quot;
          </Tip>
          <ConfigItem key={'safeMessageAmount'}>
            <Label htmlFor={'safeMessageAmount'}>Safe Message Variable</Label>
            <Input
              type="number"
              id={'safeMessageAmount'}
              name={'safeMessageAmount'}
              value={newVaultScreenParams.safeMessageAmount}
              onChange={(e) =>
                setNewVaultScreenParams((p) => ({
                  ...p,
                  safeMessageAmount: +e.target.value,
                }))
              }
            />
          </ConfigItem>
          <ConfigItem key={'safeMessageInterval'}>
            <Label htmlFor={'safeMessageInterval'}>
              Safe Message Variable Period (s)
            </Label>
            <Input
              type="number"
              id={'safeMessageInterval'}
              name={'safeMessageInterval'}
              value={newVaultScreenParams.safeMessageInterval}
              onChange={(e) =>
                setNewVaultScreenParams((p) => ({
                  ...p,
                  safeMessageInterval: +e.target.value,
                }))
              }
            />
          </ConfigItem>
          <Tip>
            For example, if the client pours 100 pints a minute, set variable to
            100 and period to 60s
          </Tip>
          <ConfigItem key={'safeScreenColour'}>
            <Label htmlFor={'safeScreenColour'}>Message Colour</Label>
            <input
              type="color"
              id={'safeScreenColour'}
              name={'safeScreenColour'}
              value={newVaultScreenParams.safeScreenColour}
              onChange={(e) =>
                setNewVaultScreenParams((p) => ({
                  ...p,
                  safeScreenColour: e.target.value,
                }))
              }
            />
          </ConfigItem>
        </>
      )}
      {useSoftwareKeyboard && keyboardVisible && (
        <KeyboardContainer onClick={(e) => e.preventDefault()}>
          <Keyboard
            keyboardRef={keyboard}
            inputName={`pourParams${currentFocusKey}`}
            layout={{
              default: ['1 2 3', '4 5 6', '7 8 9', '. 0 {bksp}'],
            }}
            display={{
              '{bksp}': '\u232B',
            }}
            onChange={(val: string) => {
              if (currentFocusKey) {
                setNewParams((p) => ({ ...p, [currentFocusKey]: +val }));
              }
            }}
          />
        </KeyboardContainer>
      )}

      {/* <GroupHeading>Load Sensor</GroupHeading>
      <Item label="Current Measured Mass" initial={32} />
      <Item label="Minimum Glass Threshold" initial={16} />
      <button style={{ width: '200px' }} onClick={() => console.log('tare')}>
        Tare
      </button> */}

      {/* <GroupHeading>Game</GroupHeading>
      <Item label="Easy Mode Offset" value={20} />
      <Item label="Medium Mode Offset" initial={40} />
      <Item label="Hard Mode Offset" initial={60} /> */}

      <GroupHeading>Headsets</GroupHeading>
      <button
        style={{
          background: 'black',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '6px 18px',
        }}
        onClick={onConnectClick}
      >
        {localHeadsetConnected ? 'Disconnect' : 'Connect to headset'}
      </button>
      {localHeadsetConnected ? (
        <>
          <span>Connected to {headsetName}</span>
          <span>
            Battery:{' '}
            {headsetBattery == undefined ? 'Unknown' : `${headsetBattery}%`}
          </span>
          <span>Focus: {localHeadsetFocus}</span>
          <span>Acceleration: {acceleration}</span>
          <span>Headset: {headsetOn ? 'ON' : 'OFF'}</span>
          <span>Min attention: {pourParams.minAttention}</span>
          <span>Max attention: {pourParams.maxAttention}</span>
          <button
            style={{
              background: 'black',
              marginBottom: '24px',
              width: 'fit-content',
              padding: '6px 18px',
            }}
            disabled={calibrateMode}
            onClick={() => setCalibrateMode(true)}
          >
            {calibrateMode ? 'Calibrating...' : 'Calibrate'}
          </button>
        </>
      ) : null}
      {['None', ...headsetList].map((h, i) => (
        <button
          key={`headset-${h}`}
          style={{
            background: 'black',
            marginBottom: '24px',
            width: 'fit-content',
            padding: '6px 18px',
          }}
          onClick={() => {
            sendWsMessage({ type: 'change_headset', data: i });
          }}
        >
          {h}
        </button>
      ))}

      <GroupHeading>Data collection</GroupHeading>

      <ConfigItem>
        <Label htmlFor="preGamePersonalDetailEntry">Pre-game collection</Label>
        <div className="select">
          <select
            id="preGamePersonalDetailEntry"
            name="preGamePersonalDetailEntry"
            value={preGamePersonalDetailEntry}
            onChange={(e) =>
              setPreGamePersonalDetailEntry(
                +e.target.value as unknown as PersonalDetailEntry
              )
            }
          >
            <option value={PersonalDetailEntry.None}>None</option>
            <option value={PersonalDetailEntry.Form}>Form</option>
          </select>
        </div>
      </ConfigItem>

      {preGamePersonalDetailEntry == PersonalDetailEntry.Form && (
        <ConfigItem>
          <Label htmlFor="skipPreGamePersonalDetailEntry">
            Allow pre-game skip?
          </Label>
          <input
            type="checkbox"
            id="skipPreGamePersonalDetailEntry"
            name="skipPreGamePersonalDetailEntry"
            checked={skipPreGamePersonalDetailEntry}
            onChange={(e) =>
              setSkipPreGamePersonalDetailEntry(e.target.checked)
            }
          />
        </ConfigItem>
      )}

      <ConfigItem>
        <Label htmlFor="postGamePersonalDetailEntry">
          Post-game collection
        </Label>
        <div className="select">
          <select
            id="postGamePersonalDetailEntry"
            name="postGamePersonalDetailEntry"
            value={postGamePersonalDetailEntry}
            onChange={(e) =>
              setPostGamePersonalDetailEntry(
                +e.target.value as unknown as PersonalDetailEntry
              )
            }
          >
            <option value={PersonalDetailEntry.None}>None</option>
            <option value={PersonalDetailEntry.Form}>Form</option>
            <option value={PersonalDetailEntry.QrCode}>QR Code</option>
          </select>
        </div>
      </ConfigItem>

      <ConfigItem>
        <Label htmlFor="collectEmail">Collect email?</Label>
        <input
          type="checkbox"
          id="collectEmail"
          name="collectEmail"
          checked={collectEmail}
          onChange={(e) => setCollectEmail(e.target.checked)}
        />
      </ConfigItem>

      <GroupHeading>Misc Settings</GroupHeading>
      <ConfigItem>
        <Label htmlFor="useSoftwareKeyboard">Use software keyboard?</Label>
        <input
          type="checkbox"
          id="useSoftwareKeyboard"
          name="useSoftwareKeyboard"
          checked={useSoftwareKeyboard}
          onChange={(e) => setUseSoftwareKeyboard(e.target.checked)}
        />
      </ConfigItem>

      <ConfigItem>
        <Label htmlFor="websocketUrl">Websocket URL</Label>
        <div className="select">
          <select
            id="websocketUrl"
            name="websocketUrl"
            value={resolvedWebsocketUrl}
            onChange={(e) =>
              setWebsocketUrl(e.target.value === 'custom' ? '' : e.target.value)
            }
          >
            {WEBSOCKET_URLS.map((url) => (
              <option key={url.url} value={url.url}>
                {url.name}
              </option>
            ))}
            <option value="custom">Custom</option>
          </select>
        </div>
      </ConfigItem>
      <ConfigItem>
        <Label htmlFor="websocketUrl">&nbsp;</Label>
        <Input
          type="text"
          readOnly={resolvedWebsocketUrl !== 'custom'}
          placeholder="e.g. wss://192.168.192.1:9092"
          style={
            resolvedWebsocketUrl === 'custom'
              ? {}
              : { color: 'gray', borderColor: 'gray' }
          }
          id="customWebsocketUrl"
          name="customWebsocketUrl"
          value={websocketUrl}
          onChange={(e) => setWebsocketUrl(e.target.value)}
        />
      </ConfigItem>

      <button
        style={{
          background: 'black',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '18px',
        }}
        onClick={toggleCursorVisibility}
      >
        Toggle cursor
      </button>

      <button
        style={{
          background: 'black',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '18px',
        }}
        onClick={() => {
          setCurrentEvent(null);
          setCurrentMode(AppState.Home);
        }}
      >
        Change event
      </button>

      <button
        style={{
          background: 'black',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '18px',
        }}
        onClick={toggleInterruptPour}
      >
        {interruptPour ? 'Allow pour' : 'Interrupt pour'}
      </button>

      <button
        style={{
          background: mockMindControl ? 'black' : 'red',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '18px',
        }}
        onClick={toggleMockMindControl}
      >
        {mockMindControl ? 'Real mind control' : 'Mock mind control'}
      </button>

      <Button
        style={{
          paddingTop: '4px',
          paddingBottom: '4px',
          // marginTop: 'auto',
          width: '50vw',
          position: 'fixed',
          bottom: '15px',
          left: '25vw',
          right: '25vw',
        }}
        onClick={() => {
          setPourParams(newParams);
          setVaultScreenParams(newVaultScreenParams);
          setEndGameDialogs(newEndGameDialogs);
          toast.success('Updated Pour Parameters');
        }}
      >
        Save
      </Button>
    </ConfigContainer>
  );
};

export default Config;

const KeyboardContainer = styled.div`
  position: fixed;
  bottom: 80px;
  height: 200px;
  display: flex;
  align-items: flex-end;
  left: 20vw;
  right: 20vw;
  z-index: 10000;
  .hg-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: stretch;
    align-items: stretch;
    gap: 5px;

    .hg-standardBtn,
    .hgfunctionBtn {
      width: auto;
      margin-right: 0 !important;
    }
  }
  span {
    color: #000;
  }
`;

const ConfigContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  /* gap: 32px; */
  padding: 64px;
  overflow: auto;
`;

const ConfigItem = styled.li`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  font-size: 32px;
  font-family: 'Bevellier', 'Inter', sans-serif;

  --form-control-focus: rgba(255, 255, 255, 0.2);
  --form-control-color: ${(p) => p.theme.colors.primary};
  --form-control-disabled: #959495;
  --select-border: ${(p) => p.theme.colors.primary};
  --select-focus: blue;
  --select-arrow: var(--select-border);

  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0 0 12px 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
  }

  input[type='checkbox']:focus {
    outline: max(2px, 0.15em) solid var(--form-control-focus);
    outline-offset: max(2px, 0.15em);
  }

  input[type='checkbox']:disabled {
    --form-control-color: var(--form-control-disabled);

    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  input[type='color'] {
    width: 30vw;
    padding: 0;
    height: 55px;
  }

  .select {
    width: 100%;
    min-width: 15ch;
    max-width: 30ch;
    border: 2px solid var(--select-border);
    padding: 0.25em 0.5em;
    font-size: inherit;
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
  }

  .select::after {
    content: '';
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    grid-area: select;
    justify-self: end;
  }

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    color: currentColor;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    // width: 30vw;
    outline: none;
    grid-area: select;
  }
`;

interface ItemProps {
  label: string;
  value: number;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (v: number) => void;
  onButtonPress?: (v: number) => void;
  buttonLabel?: string;
}

const Item = ({
  label,
  value,
  name,
  onBlur,
  onChange,
  onFocus,
  onButtonPress,
  buttonLabel = 'Send',
}: ItemProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.valueAsNumber);
  };
  return (
    <ConfigItem>
      <Label>{label}</Label>
      <Input
        type="number"
        id={name}
        name={name}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleChange}
      />
      {onButtonPress !== undefined ? (
        <Button
          style={{ width: 'fit-content', marginLeft: '10px' }}
          onClick={() => {
            onButtonPress(value);
          }}
        >
          {buttonLabel}
        </Button>
      ) : null}
    </ConfigItem>
  );
};

const Tip = styled.p`
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  margin-top: 4px;
  margin-bottom: 12px;
`;

const Label = styled.label`
  font-family: 'Bevellier', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 25px;
  min-width: 256px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const GroupHeading = styled.h2`
  font-family: 'Bevellier', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 25px;
  letter-spacing: 0.065em;
  text-transform: uppercase;
  margin-top: 48px;
  margin-bottom: 16px;
`;

const Heading = styled.h1`
  font-family: 'Bevellier', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  line-height: 55px;
`;

const Input = styled.input`
  border: none;
  outline: none;
  background-color: ${(p) => p.theme.colors.bg};
  color: ${(p) => p.theme.colors.primary};
  border: 2px solid ${(p) => p.theme.colors.primary};
  width: 30vw;
  padding: 2px;
  padding-left: 8px;

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    color: red;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

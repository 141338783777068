import { useEffect, useRef } from 'react';

import { lerp, randomValue } from './utils';
import perlin from './perlin';

type Scenario = [percentTimeElapsed: number, targetFocus: number][];

const scenarios: { [k: string]: Scenario } = {
  bad: [
    [0, 0.05],
    [0.35, 0.1],
    [0.75, 0.2],
    [1, 0.25],
  ],
  intermediate1: [
    [0, 0],
    [0.35, 0.22],
    [0.75, 0.3],
    [1, 0.38],
  ],
  intermediate2: [
    [0, 0],
    [0.35, 0.2],
    [0.75, 0.3],
    [1, 0.4],
  ],
  pro: [
    [0, 0.2],
    [0.35, 0.3],
    [0.75, 0.55],
    [1, 0.7],
  ],
};

const getScenario = (): Scenario => {
  return randomValue(Object.values(scenarios));
};

const getCurrentTarget = (
  scenario: Scenario,
  percentageTimeElapsed: number
) => {
  for (let i = 0; i < scenario.length - 1; i++) {
    const [startTime, startFocus] = scenario[i];
    const [endTime, endFocus] = scenario[i + 1];

    if (
      percentageTimeElapsed >= startTime &&
      percentageTimeElapsed <= endTime
    ) {
      const t = (percentageTimeElapsed - startTime) / (endTime - startTime);
      return lerp(startFocus, endFocus, t);
    }
  }

  return scenario[scenario.length - 1][1];
};

const perlinFunction = (targetFocus: number) => {
  const now = performance.now() / 2000;
  const perlinAdjusted = Math.abs(perlin.get(now, now) + targetFocus * 2);
  const max: number = randomValue([
    0.88, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 1.0,
  ]);

  let perlinCapped = perlinAdjusted;
  if (perlinCapped > 1) perlinCapped = max;

  return perlinCapped * 100;
};

const useMockFocus = (
  useMockedData: boolean,
  percentageTimeElapsed: number
) => {
  const scenario = useRef(getScenario());
  const targetFocus = getCurrentTarget(scenario.current, percentageTimeElapsed);

  useEffect(() => {
    if (percentageTimeElapsed === 0) {
      scenario.current = getScenario();
    }
  }, [percentageTimeElapsed]);

  return useMockedData ? perlinFunction(targetFocus) : null;
};

export default useMockFocus;

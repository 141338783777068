import { FC, useContext } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { Event, Table } from '../lib/types/db-models';
import { GlobalStateContext } from '../lib/globalStateProvider';
import { supabase } from '../lib/db/client';
import populateEventLogos from '../lib/db/populateEventLogos';

const Container = styled.div`
  /* min-height: calc(100vh - ${(props) =>
    props.theme.dimensions.navHeight}); */
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EventContainer = styled.div`
  display: grid;
  place-items: stretch;
  gap: 16px;
  height: fit-content;
  width: fit-content;
  margin: 16px auto;
  grid-template-columns: repeat(4, min(50vw/3, max(15vw, 50vw/5)));
`;

const EventItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 32px;
  border: 2px solid ${(p) => p.theme.colors.primary};
  padding: 10px;
  overflow: hidden;
  font-weight: 700;

  img {
    max-height: 100px;
    object-fit: contain;
  }
`;

interface EventSelectorProps {
  accountId: string;
}

const fetchEvents = async (): Promise<Event[] | null> => {
  const { data, error } = await supabase
    .from(Table.EVENT)
    .select('*')
    .limit(25)
    .order('created_at', { ascending: false });

  if (error) throw new Error(error.message);

  const logos = data.map(async (event) => {
    return await populateEventLogos(event);
  });

  await Promise.all(logos);

  return data;
};

const EventSelector: FC<EventSelectorProps> = ({ accountId }): JSX.Element => {
  const { setCurrentEvent } = useContext(GlobalStateContext);

  const events = useQuery(['events'], () => fetchEvents(), {
    enabled: accountId.length > 0,
  });

  return (
    <Container>
      <EventContainer>
        {events.isSuccess &&
          events.data &&
          events.data.map((event) => (
            <EventItem key={event.id} onClick={() => setCurrentEvent(event)}>
              <span>{event.name}</span>
              <img src={event.image_path ?? ''} />
            </EventItem>
          ))}
      </EventContainer>
    </Container>
  );
};

export default EventSelector;

import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AppState, useGlobalState } from '../../lib/globalStateProvider';
import { supabase } from '../../lib/db/client';
import { Table } from '../../lib/types/db-models';

import ModeSelector from '../ModeSelector';
import populateEventLogos from '../../lib/db/populateEventLogos';
import useModeChanger from '../../lib/useModeChanger';
const Home = (): JSX.Element => {
  const { currentEvent, setCurrentEvent } = useGlobalState();
  const [refreshedEvent, setRefreshedEvent] = useState(false);
  const { changeMode } = useModeChanger();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (!hasMounted) {
      changeMode(AppState.Home);
      setHasMounted(true);
    }
  }, [changeMode, hasMounted]);

  useEffect(() => {
    let mounted = true;
    if (mounted && !refreshedEvent && currentEvent) {
      const updateEvent = async () => {
        const { data, error } = await supabase
          .from(Table.EVENT)
          .select('*')
          .limit(1)
          .eq('id', currentEvent.id);
        if (!mounted) {
          return;
        }
        if (!error && data.length > 0) {
          const event = await populateEventLogos(data[0]);
          setCurrentEvent(event);
          setRefreshedEvent(true);
        } else {
          setCurrentEvent(null);
          setRefreshedEvent(true);
        }
      };
      void updateEvent();
    }
    return () => {
      mounted = false;
    };
  }, [currentEvent, refreshedEvent]);

  return (
    <HomeContainer>
      <ModeSelector />
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div`
  min-width: 100%;
  min-height: 100%;
  height: 100%;
`;

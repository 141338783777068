import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactConfetti from 'react-confetti';

import useSubmitScore from '../lib/db/useSubmitScore';
import { GameMode } from '../lib/types/game-types';

import Button from './Button';
import { FullPageContainer, Dialog } from './Layout';
import Loader from './Loader';
import ScoreQrDialog from './scores/ScoreQrDialog';
import { usePersonalDetails } from '../lib/usePersonalDetails';
import PersonalDetails from './PersonalDetails';
import {
  PersonalDetailEntry,
  useGlobalState,
} from '../lib/globalStateProvider';
import useSubmitEmail from '../lib/db/useSubmitEmail';

interface PostGameDialogProps {
  callback: () => void;
  score: number;
  timeRemaining?: number | null;
  timeSpent?: number | null;
  focusScore?: number | null;
  mode: GameMode;
  pourId: number;
  dialogStartText?: string;
  scoreUnits?: string;
  scoreCalculated?: boolean;
}

const PostGameDialog = ({
  callback,
  score,
  timeRemaining = null,
  timeSpent = null,
  focusScore = null,
  dialogStartText = 'You scored',
  scoreUnits = ' points',
  scoreCalculated = true,
  pourId,
}: PostGameDialogProps): JSX.Element => {
  const { postGamePersonalDetailEntry, endGameDialogs } = useGlobalState();
  const { confirmed, name, email } = usePersonalDetails();
  const pos = 0;
  const submit = useSubmitScore(
    pourId,
    score,
    confirmed ? name : undefined,
    timeRemaining,
    timeSpent
  );
  const submitEmail = useSubmitEmail(pourId, confirmed ? email : undefined);
  const finalScore = focusScore ?? score;
  const dialog = useMemo(() => {
    const res = endGameDialogs.find(
      (d) => score >= d.minScore && score <= d.maxScore
    );
    if (!res)
      return {
        rank: 'Congratulations!',
        text: '',
        minScore: 0,
        maxScore: 0,
      };
    return res;
  }, [endGameDialogs, finalScore]);

  // We use this so that when the state is reset we don't unset the name
  const [localName, setLocalName] = useState<string>();

  useEffect(() => {
    if (confirmed) {
      setLocalName(name);
    }
  }, [confirmed, name]);

  useEffect(() => {
    if (!scoreCalculated) {
      return;
    }
    console.log({
      id: pourId,
      score,
      time_remaining: timeRemaining,
      time_spent: timeSpent,
      name: localName,
    });
    submit.mutate({
      id: pourId,
      score,
      time_remaining: timeRemaining,
      time_spent: timeSpent,
      name: localName,
    });
  }, [scoreCalculated, localName, score, timeRemaining, timeSpent]);

  useEffect(() => {
    if (!confirmed || !email) {
      return;
    }
    submitEmail.mutate({
      id: pourId,
      email: confirmed ? email : undefined,
    });
  }, [confirmed, email]);

  const scoreToDisplay =
    timeSpent !== null ? (score === 100 ? timeSpent : score) : score;

  const scoreUnitsToDisplay =
    timeSpent !== null ? (score === 100 ? ' seconds' : scoreUnits) : scoreUnits;

  return (
    <FullPageContainer>
      <Dialog>
        {dialog.rank.length < 9 ? (
          <RankText>{dialog.rank}</RankText>
        ) : (
          <SmallerRankText>{dialog.rank}</SmallerRankText>
        )}
        <ReactConfetti
          recycle={false}
          colors={['#6FFFF6', '#EF75E0', '#434053', '#fff']}
          numberOfPieces={402}
        />
        <h1>{dialog.text}</h1>
        <p>
          {`${dialogStartText} `}
          <span style={{ fontSize: '32px' }}>{scoreToDisplay.toFixed(2)}</span>
          {scoreUnitsToDisplay}.
        </p>
        {postGamePersonalDetailEntry === PersonalDetailEntry.Form &&
          !confirmed && <PersonalDetails />}
        {postGamePersonalDetailEntry === PersonalDetailEntry.QrCode &&
          !confirmed && (
            <>
              <p>Scan the QR code to add your name to the scoreboard</p>
              {pourId && submit.isSuccess ? (
                <ScoreQrDialog score={score} pos={pos} scoreId={pourId} />
              ) : (
                <div style={{ width: '196px', height: '196px' }}>
                  <Loader />
                </div>
              )}
            </>
          )}

        <Button onClick={callback}>HOME</Button>
      </Dialog>
    </FullPageContainer>
  );
};

export default PostGameDialog;

const RankText = styled.h1`
  font-size: 120px;
`;
const SmallerRankText = styled.h1`
  font-size: 80px;
`;

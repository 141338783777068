import { useCallback, useEffect, useRef, useState } from 'react';
// import { useTimer } from 'react-timer-hook';
import { useCountdown } from 'usehooks-ts';
import {
  PourParams,
  SafeScoreAlgorithm,
  SafeVersion,
  useGlobalState,
} from './globalStateProvider';
import { UsePourController } from './types/pourController';

import useInterval from './useInterval';
import useSafe from './useSafe';

const calculateCurrentPercentage = (
  currentTimeRemaining: number,
  currentStage: number,
  pourParams: PourParams
): number => {
  if (currentTimeRemaining <= 0) {
    return 0;
  } else {
    const currentStageDuration =
      currentStage == 0
        ? pourParams.safeLockTime1
        : currentStage == 1
        ? pourParams.safeLockTime2
        : pourParams.safeLockTime3;
    return 1 - currentTimeRemaining / currentStageDuration;
  }
};

const calculateTotalPercentage = (
  currentTimeRemaining: number,
  currentStage: number,
  pourParams: PourParams
): number => {
  if (currentStage > 2) {
    return 1;
  }
  const percentage =
    currentStage +
    calculateCurrentPercentage(currentTimeRemaining, currentStage, pourParams);
  return percentage / 3;
};

const useSafeController = (
  onAbortCallback?: () => void,
  onCompleteCallback?: (stage: number) => void
): UsePourController => {
  const { pourParams } = useGlobalState();
  const {
    setInThreshold,
    currentStage,
    setCurrentStage,
    currentGameState,
    setCurrentGameState,
    sendV2SafeStatus,
    startGame,
    completeGame,
  } = useSafe();
  const [hasFinished, setHasFinished] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [focuses, setFocuses] = useState<number[]>([]);
  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart:
        pourParams.safeGameDuration / pourParams.safeScoreIntervalSeconds,
      intervalMs: pourParams.safeScoreIntervalSeconds * 1000,
    });
  const gameTime = new Date();
  gameTime.setSeconds(new Date().getSeconds() + pourParams.safeGameDuration);
  const currentStageTime = useRef(0);
  const localCurrentStage = useRef(0);
  const { sendVaultStatusMessage } = useGlobalState();
  const [percentageComplete, setPercentageComplete] = useState(0);
  const [scoreCalculated, setScoreCalculated] = useState(false);

  const onComplete = () => {
    console.log('TIMER HAS COMPLETE');
    setHasFinished(true);
    setIsRunning(false);
    stopCountdown();
    completeGame(localCurrentStage.current >= 3);
    // setCupDetected(false)

    if (onCompleteCallback) onCompleteCallback(currentStage);

    // TODO: have state managed in web app and pass through websocekt on change
    // sendWsMessage({ type: 'change_mode', data: AppState.Home });
  };

  const onStart = () => {
    setHasFinished(false);
    setIsRunning(true);
    setFocuses([]);
    if (pourParams.safeVersion === SafeVersion.V2) {
      startGame();
      setScoreCalculated(false);
      setPercentageComplete(0);
      setCurrentGameState('started');
      setCurrentStage(0);
      localCurrentStage.current = 0;
      currentStageTime.current = pourParams.safeLockTime1;
    }
    console.log('starting timer...');
    resetCountdown();
    startCountdown();
  };

  const stopPour = () => {
    stopCountdown();
    setIsRunning(false);
  };

  const secondsRemaining = Math.ceil(
    count * pourParams.safeScoreIntervalSeconds
  );

  const previousTime = useRef(-1);
  const isInPractice = currentGameState !== 'started';

  useEffect(() => {
    switch (currentGameState) {
      case 'aborted':
        stopPour();
        if (onAbortCallback) onAbortCallback();
        break;
      case 'won':
        console.log('YOU WON');
        stopPour();
        setPercentageComplete(100);
        setScoreCalculated(true);
        onComplete();
        sendVaultStatusMessage({
          mode: 'score',
          time: secondsRemaining,
          elapsed: pourParams.safeGameDuration - secondsRemaining,
          score: 1,
        });
        break;
      case 'lost':
        console.log('YOU LOST');
        stopPour();
        setPercentageComplete(
          calculateTotalPercentage(
            currentStageTime.current,
            localCurrentStage.current,
            pourParams
          ) * 100
        );
        setScoreCalculated(true);
        onComplete();
        sendVaultStatusMessage({
          mode: 'score',
          time: secondsRemaining,
          elapsed: pourParams.safeGameDuration - secondsRemaining,
          score: calculateTotalPercentage(
            currentStageTime.current,
            localCurrentStage.current,
            pourParams
          ),
        });
        break;
    }
  }, [sendVaultStatusMessage, currentGameState, secondsRemaining, pourParams]);

  useInterval(
    () => {
      if (isInPractice) {
        // console.log('in practice, dont send thresholds');
        return;
      }

      if (previousTime.current != count) {
        console.log('count: %d, secondsRemaining: %d', count, secondsRemaining);
        sendVaultStatusMessage({
          mode: 'time',
          time: secondsRemaining,
        });
        // Send `isInThreshold`
        const recentFocuses = focuses.slice(0, pourParams.safeScoreSampleSize);
        let averageFocus = 0;
        let algorithm = 'unknown';

        if (recentFocuses.length) {
          switch (pourParams.safeScoreAlgorithm) {
            case SafeScoreAlgorithm.Average:
              algorithm = 'average';
              averageFocus =
                recentFocuses.reduce((s, a) => s + a, 0) / recentFocuses.length;
              break;
            case SafeScoreAlgorithm.AverageNoZero:
              algorithm = 'average (no zero)';
              const noZeroFocuses = recentFocuses.filter((f) => f !== 0);
              if (noZeroFocuses.length) {
                averageFocus =
                  noZeroFocuses.reduce((s, a) => s + a, 0) /
                  noZeroFocuses.length;
              }
              break;
            case SafeScoreAlgorithm.Max:
              algorithm = 'max';
              averageFocus = Math.max(...recentFocuses);
              break;
            case SafeScoreAlgorithm.Min:
              algorithm = 'min';
              averageFocus = Math.min(...recentFocuses);
              break;
          }
        }

        console.log(algorithm, averageFocus, recentFocuses);

        const stageToUse =
          pourParams.safeVersion === SafeVersion.V2
            ? localCurrentStage.current
            : currentStage;

        const lockThreshold =
          stageToUse === 0
            ? pourParams.safeLockThreshold1
            : stageToUse === 1
            ? pourParams.safeLockThreshold2
            : pourParams.safeLockThreshold3;
        if (pourParams.safeVersion === SafeVersion.V2) {
          if (averageFocus > lockThreshold) {
            currentStageTime.current -= pourParams.safeScoreIntervalSeconds;
          }
          let percentage = 0;
          if (currentStageTime.current <= 0) {
            setCurrentStage(stageToUse + 1);
            localCurrentStage.current += 1;
            currentStageTime.current =
              localCurrentStage.current == 1
                ? pourParams.safeLockTime2
                : pourParams.safeLockTime3;
            percentage = 0;
          } else {
            const currentStageDuration =
              localCurrentStage.current == 0
                ? pourParams.safeLockTime1
                : localCurrentStage.current == 1
                ? pourParams.safeLockTime2
                : pourParams.safeLockTime3;
            percentage = 1 - currentStageTime.current / currentStageDuration;
          }
          sendV2SafeStatus(
            averageFocus > lockThreshold,
            localCurrentStage.current,
            averageFocus,
            percentage,
            localCurrentStage.current >= 3
          );
          if (localCurrentStage.current >= 3) {
            setCurrentGameState('won');
            onComplete();
          }
        } else {
          setInThreshold(
            averageFocus > lockThreshold,
            stageToUse,
            averageFocus
          );
        }
        previousTime.current = count;
      }
      if (count <= 0) {
        if (pourParams.safeVersion === SafeVersion.V2) {
          setCurrentGameState('lost');
        }
        onComplete();
      }
    },
    isRunning && !hasFinished ? 100 : null
  );

  const setAnglesForScore = useCallback((score: number) => {
    setFocuses((f) => [score, ...f.slice(0, 30)]);
  }, []);

  return {
    pourTime: pourParams.safeGameDuration,
    practiceTime: 0,
    hasFinished,
    start: onStart,
    secondsRemaining,
    setAnglesForScore,
    isPouring: false,
    stop: stopPour,
    inPractice: isInPractice,
    percentageComplete,
    scoreCalculated,
    isRunning,
  };
};

export default useSafeController;

import {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';

import { AppState, useGlobalState } from '../globalStateProvider';
import useInterval from '../useInterval';

interface WSProviderProps {
  children: JSX.Element;
  url: string;
}

enum WebSocketState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

const connectionId = 'd34i-ba8n';

export interface ConnectionStatusData {
  robot_connected: boolean;
  headset_connection: number;
}

export type MessageType =
  | 'connection'
  | 'change_mode'
  | 'change_headset'
  | 'headset_list'
  | 'mode_changed'
  | 'start_pour'
  | 'pour_started'
  | 'pour_progress'
  | 'mind_reading'
  | 'has_cup'
  | 'tap'
  | 'connection_status';

export interface UpdateAngles {
  type: 'update_angle';
  data: {
    glass_angle?: number;
    tap_angle?: number;
  };
}

export interface ChangeDefaultAngles {
  type: 'change_default_angles';
  data: {
    glass_best_angle: number;
    glass_worst_angle: number;
    tap_off_angle: number;
    tap_on_angle: number;
  };
}

export interface HeadsetList {
  type: 'headset_list';
  data: string[];
}

export interface InterruptPourChanged {
  type: 'interrupt_pour_changed' | 'interrupt_pour';
  data: boolean;
}

export interface SafeInThreshold {
  type: 'safe_in_threshold';
  data: {
    in_threshold: boolean;
    stage: number;
    focus: number;
  };
}

export interface SetSafeStage {
  type: 'set_safe_stage';
  data: number;
}

export type SafeGameState = 'pending' | 'started' | 'won' | 'lost' | 'aborted';

export interface SetSafeGameState {
  type: 'set_safe_game_state';
  data: SafeGameState;
}

export interface SafeV2Reset {
  type: 'safe_v2_reset';
  data: {
    defaultStageColour: [number, number, number];
    completedStageColour: [number, number, number];
    currentStageColour: [number, number, number];
    idleColours: [number, number, number][];
    idleColoursInterval: number;
    completeColours: [number, number, number][];
    completeColoursInterval: number;
    animate?: boolean;
  };
}

export interface SafeV2Start {
  type: 'safe_v2_start';
}

export interface SafeV2End {
  type: 'safe_v2_end';
  data: {
    result: boolean;
  };
}

export interface SafeV2Open {
  type: 'safe_v2_open';
}

export interface SafeV2Status {
  type: 'safe_status';
  data: {
    focusInThreshold: boolean;
    stage: number;
    focus: number;
    currentStageProgress: number;
    completedStageColour: [number, number, number];
    currentStageColour: [number, number, number];
    defaultStageColour: [number, number, number];
    idleColours: [number, number, number][];
    completeColours: [number, number, number][];
    complete: boolean;
    dialRotationAmount: number;
    dialRotationInterval: number;
  };
}

const RETRY_LIMIT = 5;

// TODO: improve these types, if we know what message type then we should know the shape of the data
interface WSMessageData {
  type: MessageType;
  data: string | number;
}

type MessageData =
  | WSMessageData
  | UpdateAngles
  | ChangeDefaultAngles
  | HeadsetList
  | InterruptPourChanged
  | SetSafeStage
  | SetSafeGameState
  | SafeInThreshold
  | SafeV2Reset
  | SafeV2Start
  | SafeV2End
  | SafeV2Open
  | SafeV2Status;

interface WSContext {
  ws: WebSocket | undefined;
  tryConnection: (connectionId: string) => void;
  sendWsMessage: (msg: MessageData) => void;
  lastMessage: MessageData;
}

export const WebSocketContext = createContext<WSContext>({} as WSContext);

export const useWebSocket = (): WSContext => useContext(WebSocketContext);

export const WebSocketProvider = ({
  url,
  children,
}: WSProviderProps): JSX.Element => {
  const {
    setConnectionStatus,
    hasRobotConnection,
    setCurrentMode,
    setCupDetected,
    hasConnectionError,
    setHasConnectionError,
    setConnectionStatusData,
    setHeadsetList,
    connectionStatus,
    pourParams,
    setInterruptPour,
    enableVaultScreen,
  } = useGlobalState();

  const ws = useRef<WebSocket>();
  const [hasBaseConnection, setHasBaseConnection] = useState(false);
  const retryAttempts = useRef(0);
  const [readyState, setReadyState] = useState(0);

  // TODO: use ref for this to avoid re-renders
  const [lastMessage, setLastMessage] = useState<MessageData>(
    {} as WSMessageData
  );
  // const lastMessage = useRef<WSMessageData>({} as WSMessageData);

  const sendWsMessage = useCallback((message: MessageData) => {
    if (ws.current?.readyState !== WebSocketState.OPEN) {
      setHasConnectionError(true);
      return;
    }
    const jsonMessage = JSON.stringify(message);
    try {
      ws.current?.send(jsonMessage);
    } catch (error) {
      console.error('error sending msg: ', error);
      setHasConnectionError(true);
      console.log(hasConnectionError);
    }
  }, []);

  useInterval(
    () => {
      if (retryAttempts.current <= RETRY_LIMIT) {
        console.log({ bc: hasBaseConnection });
        setHasBaseConnection(ws.current?.readyState === WebSocketState.OPEN);
        const rs =
          typeof ws.current?.readyState === 'number'
            ? WebSocketState[ws.current?.readyState]
            : undefined;

        console.log({ readyState: rs });

        if (ws.current) {
          if (
            [WebSocketState.CONNECTING, WebSocketState.OPEN].includes(
              ws.current.readyState
            )
          ) {
            return;
          }
          console.log('Closing, state is', ws.current.readyState);
          ws.current.close();
        }

        try {
          ws.current = new WebSocket(url);
        } catch (error) {
          console.error(error);
        }

        retryAttempts.current += 1;
      } else {
        setHasConnectionError(true);
      }
    },
    hasBaseConnection || hasConnectionError ? null : 3000,
    () => {
      if (ws.current) {
        ws.current.close();
        ws.current = undefined;
      }
    }
  );

  useInterval(() => {
    setReadyState(ws.current?.readyState ?? 0);
  }, 500);

  const initWs = useCallback(() => {
    console.log('INIT WS');
    console.log({ connectionId });
    if (ws.current) {
      ws.current.onopen = () => {
        console.log('on open...');
        tryBackendConnection(connectionId);
      };
      ws.current.onmessage = (e: MessageEvent) => {
        let message;

        try {
          message = JSON.parse(e.data);
        } catch (error) {
          message = JSON.stringify(error);
        }
        // TODO: use refs here to avoid re-rendering whole app
        setLastMessage(message);
      };
      ws.current.onerror = (e) => {
        throw new Error(JSON.stringify(e));
      };
    }
  }, []);

  useEffect(() => {
    console.log(`CURRENT STATE: ${readyState}`);
    if (readyState === WebSocketState.OPEN) {
      setHasBaseConnection(false);
      initWs();
    }
  }, [readyState, initWs]);

  useEffect(() => {
    if (connectionStatus) {
      sendWsMessage({
        type: 'change_default_angles',
        data: {
          glass_best_angle: pourParams.glassBestAngle,
          glass_worst_angle: pourParams.glassWorstAngle,
          tap_off_angle: pourParams.tapOffAngle,
          tap_on_angle: pourParams.tapOnAngle,
        },
      });
    }
  }, [
    connectionStatus,
    pourParams.glassBestAngle,
    pourParams.glassWorstAngle,
    pourParams.tapOffAngle,
    pourParams.tapOnAngle,
  ]);

  useEffect(() => {
    if (ws.current) {
      if (hasBaseConnection && !hasRobotConnection) {
        tryBackendConnection(connectionId);
      }
    }
  }, [hasBaseConnection, hasRobotConnection]);

  useEffect(() => {
    retryAttempts.current = 0;

    return () => {
      console.log('CLOSING CONNECTION');
      ws?.current?.close();
      ws.current = undefined;
      retryAttempts.current = 0;
    };
  }, []);

  useEffect(() => {
    if (lastMessage.type == 'connection') {
      console.log('CONNECTION');
      setConnectionStatus(true);
    }

    if (lastMessage.type === 'connection_status') {
      const data = JSON.parse(
        lastMessage.data as string
      ) as ConnectionStatusData;
      setConnectionStatusData(data);
    }

    if (lastMessage.type == 'mode_changed') {
      setCurrentMode(lastMessage.data as AppState);
    }

    if (enableVaultScreen) {
      return;
    }

    if (lastMessage.type == 'headset_list') {
      setHeadsetList(lastMessage.data as string[]);
    }

    if (lastMessage.type == 'interrupt_pour_changed') {
      setInterruptPour(lastMessage.data as boolean);
    }

    if (lastMessage.type === 'has_cup') {
      const hasCup = parseInt(lastMessage.data as string) === 0 ? false : true;
      setCupDetected(hasCup);
    }
  }, [lastMessage, enableVaultScreen]);

  const tryBackendConnection = (connectionId: string) => {
    const msg = JSON.stringify({
      type: 'connection',
      connectionId,
    });

    if (
      ws.current !== undefined &&
      ws.current.readyState === WebSocketState.OPEN
    ) {
      try {
        ws.current.send(msg);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        ws: ws.current,
        tryConnection: tryBackendConnection,
        sendWsMessage,
        lastMessage,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

import styled from 'styled-components';
import { GameMode, useGlobalState } from '../lib/globalStateProvider';
import {
  HeadsetQuality,
  useLocalMindControl,
} from '../lib/localMindControlProvider';

// const getHeadsetConnectionStrength = (s: number): TConnectionStrength => {
//   if (s === 200) return 'none';
//   else if (s > 150 && s < 200) return 'poor';
//   else if (s <= 150 && s > 50) return 'medium';
//   else return 'good';
// };

const getLocalHeadsetConnectionStrength = (
  q: HeadsetQuality
): TConnectionStrength => {
  switch (q) {
    case HeadsetQuality.BAD:
      return 'poor';
    case HeadsetQuality.MEDIUM:
      return 'medium';
    case HeadsetQuality.GOOD:
      return 'good';
  }
};

const getGameModeIcon = (m: GameMode): string => {
  switch (m) {
    case GameMode.Beer:
      return '🍺';
    case GameMode.Coffee:
      return '☕️';
    case GameMode.Swing:
      return '⚖️';
  }
};

const ConnectionStatusDisplay = () => {
  const { headsetQuality } = useLocalMindControl();
  const {
    mockMindControl,
    gameMode,
    connectionStatusData: d,
    localHeadsetConnected,
  } = useGlobalState();
  return (
    <Wrapper>
      <GameModeIcon>{getGameModeIcon(gameMode)}</GameModeIcon>
      <span>
        {d.robot_connected ? (
          'Robot connected'
        ) : (
          <MockedStatus>Robot DISCONNECTED</MockedStatus>
        )}
      </span>
      <span>
        {mockMindControl ? (
          <MockedStatus>Headset CONNECTED</MockedStatus>
        ) : (
          <>
            {localHeadsetConnected
              ? 'Headset connected'
              : 'Headset disconnected'}
            {localHeadsetConnected ? (
              <ConnectionStrength
                strength={getLocalHeadsetConnectionStrength(headsetQuality)}
              />
            ) : null}
          </>
        )}
      </span>
      <span></span>
    </Wrapper>
  );
};

export default ConnectionStatusDisplay;

const GameModeIcon = styled.span`
  font-size: 32px;
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  padding: 8px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  span {
    font-family: monospace;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
  }
  ${GameModeIcon} {
    font-family: inherit;
    font-size: 18px;
  }
`;

const MockedStatus = styled.span`
  color: red !important;
  font-weight: bold;
`;

type TConnectionStrength = 'poor' | 'medium' | 'good' | 'none';

const ConnectionStrength = ({
  strength,
}: {
  strength: TConnectionStrength;
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {strength === 'good' ? (
        <>
          <path
            d="M12 20V10"
            stroke="#13FC8C"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 20V4"
            stroke="#13FC8C"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 20V16"
            stroke="#13FC8C"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : strength === 'medium' ? (
        <>
          <path
            d="M12 20V10"
            stroke="#FFB017"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.4"
            d="M18 20V4"
            stroke="#ECECEC"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 20V16"
            stroke="#FFB017"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : strength === 'poor' ? (
        <>
          <path
            opacity="0.4"
            d="M12 20V10"
            stroke="#ECECEC"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.4"
            d="M18 20V4"
            stroke="#ECECEC"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 20V16"
            stroke="#FF1717"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : strength === 'none' ? (
        <>
          <path
            d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z"
            stroke="#FF1717"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 9L9 15"
            stroke="#FF1717"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 9L15 15"
            stroke="#FF1717"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <></>
      )}
    </svg>
  );
};

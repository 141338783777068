import { HTMLInputTypeAttribute, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { SimpleKeyboard } from 'react-simple-keyboard';
import styled from 'styled-components';
import Input from './Input';
import Keyboard from './Keyboard';
import { usePersonalDetails } from '../lib/usePersonalDetails';
import { useGlobalState } from '../lib/globalStateProvider';
import Button from './Button';

const MAX_NAME_LENGTH = 6;

const PersonalDetails = ({
  skippable = false,
  isSafeMode = false,
  onSkip,
}: {
  isSafeMode?: boolean;
  skippable?: boolean;
  onSkip?: () => void;
}): JSX.Element => {
  const keyboard = useRef<SimpleKeyboard>();
  const { name, setName, email, setEmail, confirm, isEmailValid, isNameValid } =
    usePersonalDetails();
  const { collectEmail, useSoftwareKeyboard, sendVaultStatusMessage } =
    useGlobalState();
  const [keyboardVisible, setKeyboardVisible] = useState(true);
  const [currentFocusKey, setCurrentFocusKey] = useState<'name' | 'email'>();

  useEffect(() => {
    if (!isSafeMode) {
      return;
    }
    sendVaultStatusMessage({
      mode: 'name',
      name,
    });
  }, [isSafeMode, name, sendVaultStatusMessage]);

  useEffect(() => {
    if (
      useSoftwareKeyboard &&
      keyboardVisible &&
      keyboard.current !== undefined &&
      currentFocusKey !== undefined
    ) {
      keyboard.current.setInput(currentFocusKey == 'name' ? name : email);
    }
  }, [useSoftwareKeyboard, name, email, currentFocusKey, keyboardVisible]);

  return (
    <>
      <Wrapper>
        <p>Provide your name so that you can be added to the scoreboard</p>
        <Item
          label="Name"
          value={name}
          autoFocus
          name="name"
          maxLength={MAX_NAME_LENGTH}
          onFocus={() => {
            setKeyboardVisible(true);
            setCurrentFocusKey('name');
          }}
          onBlur={() => {
            setKeyboardVisible(false);
          }}
          onChange={(val) => setName(val)}
        />
        {collectEmail && (
          <Item
            label="Email"
            value={email}
            type="email"
            name="email"
            onFocus={() => {
              setKeyboardVisible(true);
              setCurrentFocusKey('email');
            }}
            onBlur={() => {
              setKeyboardVisible(false);
            }}
            onChange={(val) => setEmail(val)}
          />
        )}
        <Button disabled={!isNameValid || !isEmailValid} onClick={confirm}>
          Submit
        </Button>
        {skippable && <SkipButton onClick={onSkip}>Skip</SkipButton>}
      </Wrapper>
      {useSoftwareKeyboard &&
        keyboardVisible &&
        createPortal(
          <KeyboardContainer onClick={(e) => e.preventDefault()}>
            <Keyboard
              keyboardRef={keyboard}
              inputName={currentFocusKey}
              initialLayoutName={'default'}
              maxLength={{
                name: MAX_NAME_LENGTH,
              }}
              onChange={(val: string) => {
                switch (currentFocusKey) {
                  case 'name':
                    setName(val.toLocaleUpperCase());
                    break;
                  case 'email':
                    setEmail(val);
                    break;
                }
              }}
            />
          </KeyboardContainer>,
          document.body
        )}
    </>
  );
};

interface ItemProps {
  label: string;
  value: string;
  name?: string;
  autoFocus?: boolean;
  maxLength?: number;
  type?: HTMLInputTypeAttribute;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (v: string) => void;
}

const SkipButton = styled.button`
  background: none;
  outline: none;
  border: none;
  color: #ffffff88;
  padding: 12px 64px;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Bevellier', 'Inter', sans-serif;
  letter-spacing: 0.105em;
`;

const KeyboardContainer = styled.div`
  position: fixed;
  z-index: 10000;
  bottom: 25px;
  height: 200px;
  display: flex;
  align-items: flex-end;
  left: 25px;
  right: 25px;
  span {
    color: #000;
  }
`;

const Item = ({
  label,
  value,
  name,
  autoFocus = false,
  type = 'text',
  maxLength,
  onBlur,
  onChange,
  onFocus,
}: ItemProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };
  return (
    <PersonalDetailsItem>
      <Label htmlFor={name}>{label}</Label>
      <Input
        autoComplete="off"
        autoFocus={autoFocus}
        maxLength={maxLength}
        type={type}
        id={name}
        name={name}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleChange}
      />
    </PersonalDetailsItem>
  );
};

const PersonalDetailsItem = styled.li`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  font-size: 32px;
  justify-content: space-between;
`;

const Label = styled.label`
  font-family: 'Bevellier', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 25px;
  min-width: 10vw;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export default PersonalDetails;

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useCreatePour from '../../lib/db/useCreatePour';
import { AppState, useGlobalState } from '../../lib/globalStateProvider';
import usePourController from '../../lib/usePourController';
import useModeChanger from '../../lib/useModeChanger';

const ButtonMode = ({ callback }: { callback: () => void }): JSX.Element => {
  const { currentMode, pourParams, currentEvent } = useGlobalState();
  const accountId = '92ba90d6-6dd4-40ea-965a-2337609e28ec';
  const { changeMode } = useModeChanger();

  if (currentEvent == null) {
    throw new Error('No event selected');
  }

  const buttonPour = useCreatePour(accountId, currentEvent.id, 'Button');

  useEffect(() => {
    changeMode(AppState.Button);
    setTimeout(() => handleClick(), 300);
  }, []);

  const goHome = () => {
    console.log('trying to go home');
    callback();
    stop();
    changeMode(AppState.Home);
  };

  const { start, secondsRemaining, isPouring, stop } = usePourController(
    goHome,
    false
  );

  const handleClick = () => {
    if (isPouring) {
      console.log('alreading pouring, click ignored');
      stop();
      goHome();
      return;
    }
    console.log('pressed the button!');
    buttonPour.mutate({ id: accountId, eventId: currentEvent.id, m: 'Button' });
    start(pourParams.tapOnAngle, pourParams.glassBestAngle);
  };

  return (
    <div style={{ position: 'relative', padding: '12px' }}>
      {currentMode !== AppState.Button && (
        <UnlockOverlay>
          <div>initialising...</div>
        </UnlockOverlay>
      )}
      <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
        <Pushable>
          <Front
            initial={{ y: '-6px' }}
            onTap={handleClick}
            transition={{ duration: 0.02 }}
          >
            {isPouring ? 'STOP' : 'POUR'}
          </Front>
        </Pushable>
        <div style={{ width: '100%', marginTop: '8px' }}>
          <Progress
            value={pourParams.pourDurationSeconds - secondsRemaining}
            max={pourParams.pourDurationSeconds}
          />
        </div>
      </div>
    </div>
  );
};

export default ButtonMode;

const Pushable = styled.button`
  max-width: 200px;
  max-height: 200px;

  background-color: #1d1919;
  border-radius: 50%;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none; // we can remove the outline as it's not needed for accesibility in this use case
`;

const Front = styled(motion.span)`
  padding: 64px 48px;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 4px;
  display: block;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.secondary};
  will-change: transform;
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  transform: translateY(-6px);
`;

const UnlockOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(200, 200, 200, 0.8);

  z-index: 5;

  display: grid;
  place-content: center;
`;

export const ButtonWrapper = (): JSX.Element => {
  const [enabled, setEnabled] = useState(false);

  if (enabled) {
    return <ButtonMode callback={() => setEnabled(false)} />;
  }

  return (
    <Container>
      <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
        <Pushable disabled={false}>
          <Front
            initial={{ y: '-6px' }}
            onTap={() => setEnabled(true)}
            transition={{ duration: 0.02 }}
          >
            POUR
          </Front>
        </Pushable>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  color: black;
  display: grid;
  place-content: center;
  button {
    color: black;
  }
`;

const Progress = ({ value, max }: { value: number; max: number }) => {
  return (
    <ProgressOuter>
      <motion.span
        transition={{ duration: 1 }}
        animate={{ width: `${(value / max) * 100}%` }}
      />
    </ProgressOuter>
  );
};

const noise = 'url(/images/grain.svg)';
const ProgressOuter = styled.div`
  width: 100%;
  height: 12px;
  background-color: black;
  border-radius: 8px;

  position: relative;

  span {
    border-radius: inherit;
    position: absolute;
    /* width: 100%; */
    height: 100%;
    background: ${noise},
      linear-gradient(
        93.41deg,
        ${(p) =>
          p.theme.colors.buttonGradient
            .map((g) => `${g.color} ${g.offset}`)
            .join(', ')}
      );
  }
`;

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useWebSocket, SafeGameState } from './sockets/ws-provider';
import {
  useGlobalState,
  transformToRgb,
  transformSafeColourToRgbArray,
} from './globalStateProvider';

interface UseSafe {
  setInThreshold: (inThreshold: boolean, stage: number, focus: number) => void;
  currentStage: number;
  currentGameState: SafeGameState;
  setCurrentGameState: (state: SafeGameState) => void;
  setCurrentStage: (stage: number) => void;
  sendV2SafeStatus: (
    focusInThreshold: boolean,
    stage: number,
    focus: number,
    currentStageProgress: number,
    complete: boolean
  ) => void;
  startGame: () => void;
  completeGame: (result: boolean) => void;
}

const useSafe = (): UseSafe => {
  const { sendWsMessage, lastMessage } = useWebSocket();
  const { pourParams } = useGlobalState();
  const [currentStage, setCurrentStage] = useState<number>(0);
  const [currentGameState, setCurrentGameState] =
    useState<SafeGameState>('pending');

  const defaultStageColour = useMemo(
    () => transformToRgb(pourParams.safeDefaultColour),
    [pourParams.safeDefaultColour]
  );

  const completedStageColour = useMemo(
    () => transformToRgb(pourParams.safeCompletedColour),
    [pourParams.safeCompletedColour]
  );

  const currentStageColour = useMemo(
    () => transformToRgb(pourParams.safeInProgressColour),
    [pourParams.safeInProgressColour]
  );

  const idleColours = useMemo(
    () =>
      transformSafeColourToRgbArray(
        pourParams.safeIdleSelection,
        pourParams.safeIdleCustomColour
      ),
    [pourParams.safeIdleSelection, pourParams.safeIdleCustomColour]
  );

  const completeColours = useMemo(
    () =>
      transformSafeColourToRgbArray(
        pourParams.safeCompleteSelection,
        pourParams.safeCompleteCustomColour
      ),
    [pourParams.safeCompleteSelection, pourParams.safeCompleteCustomColour]
  );

  useEffect(() => {
    if (lastMessage.type === 'set_safe_stage') {
      setCurrentStage(lastMessage.data);
    }
    if (lastMessage.type === 'set_safe_game_state') {
      setCurrentGameState(lastMessage.data);
    }
  }, [lastMessage]);

  const setInThreshold = useCallback(
    (inThreshold: boolean, stage: number, focus: number) => {
      sendWsMessage({
        type: 'safe_in_threshold',
        data: {
          in_threshold: inThreshold,
          stage,
          focus,
        },
      });
    },
    [sendWsMessage]
  );

  const startGame = useCallback(() => {
    sendWsMessage({
      type: 'safe_v2_reset',
      data: {
        defaultStageColour,
        completedStageColour,
        currentStageColour,
        idleColours,
        idleColoursInterval: pourParams.safeCompleteInterval,
        completeColours,
        completeColoursInterval: pourParams.safeIdleInterval,
      },
    });
    sendWsMessage({
      type: 'safe_v2_start',
    });
  }, [
    defaultStageColour,
    completedStageColour,
    currentStageColour,
    idleColours,
    completeColours,
    pourParams.safeCompleteInterval,
    pourParams.safeIdleInterval,
  ]);

  const completeGame = useCallback((result: boolean) => {
    sendWsMessage({
      type: 'safe_v2_end',
      data: {
        result,
      },
    });
  }, []);

  const sendV2SafeStatus = useCallback(
    (
      focusInThreshold: boolean,
      stage: number,
      focus: number,
      currentStageProgress: number,
      complete: boolean
    ) => {
      sendWsMessage({
        type: 'safe_status',
        data: {
          focusInThreshold,
          stage,
          focus,
          currentStageProgress,
          completedStageColour,
          currentStageColour,
          defaultStageColour,
          complete,
          idleColours,
          completeColours,
          dialRotationInterval: pourParams.safeDialRotationInterval,
          dialRotationAmount: pourParams.safeDialRotationAmount,
        },
      });
    },
    [
      sendWsMessage,
      pourParams.safeIdleSelection,
      pourParams.safeCompleteSelection,
      pourParams.safeDialRotationAmount,
      idleColours,
      completeColours,
      defaultStageColour,
      currentStageColour,
      completedStageColour,
    ]
  );

  return {
    setInThreshold,
    currentStage,
    setCurrentStage,
    currentGameState,
    setCurrentGameState,
    sendV2SafeStatus,
    startGame,
    completeGame,
  };
};

export default useSafe;

import { useState, useRef, useEffect, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';

import PreGameDialog from '../PreGameDialog';
import PostGameDialog from '../PostGameDialog';
import ExitButton from '../icons/ExitButton';

import useMindControl from '../../lib/useMindControl';
import useInterval from '../../lib/useInterval';
import { mapRange } from '../../lib/utils';
import { AppState, useGlobalState } from '../../lib/globalStateProvider';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import { mindSketch } from '../games/mind/main';
import { LoaderWithMessage } from '../Loader';
import useCreatePour from '../../lib/db/useCreatePour';
import {
  HeadsetQuality,
  useLocalMindControl,
} from '../../lib/localMindControlProvider';
import useModeChanger from '../../lib/useModeChanger';
import { usePersonalDetails } from '../../lib/usePersonalDetails';
import useSafeController from '../../lib/useSafeController';

interface SafeModeProps {
  accountId: string;
}

const SafeMode = ({ accountId }: SafeModeProps): JSX.Element => {
  const {
    pourParams,
    connectionStatusData,
    currentEvent,
    localHeadsetConnected,
    mockMindControl,
  } = useGlobalState();
  const { confirmed, name } = usePersonalDetails();
  const { changeMode } = useModeChanger();
  const { headsetQuality, headsetOn } = useLocalMindControl();
  const [showCountdown] = useState(false);
  const [abort, setAbort] = useState(false);
  const theme = useTheme();

  const pController = useSafeController(() => setAbort(true));
  const secondsRemaining = pController.secondsRemaining;
  const { start, pause, focusLevel, isRunning } = useMindControl(
    mockMindControl,
    secondsRemaining
  );

  const headsetDetected =
    connectionStatusData.headset_connection < 200 || localHeadsetConnected;

  const prevSnapshotTime = useRef(-1);

  const showIntro =
    !showCountdown &&
    !isRunning &&
    !pController.hasFinished &&
    !pController.isRunning;
  const showOutro = pController.hasFinished;

  if (currentEvent == null) {
    throw new Error('No event selected');
  }

  const pourDbEntry = useCreatePour(
    accountId,
    currentEvent.id,
    'Safe',
    '',
    'easy'
  );

  const goHome = useCallback(() => {
    changeMode(AppState.Home);
  }, [changeMode]);

  const BASE_FOCUS_LEVEL_REQUIRED = pourParams.minFocus;
  const DIFF_OFFSET = pourParams.minScoreMapping;

  // TODO: move into a game mode hook
  const score = useRef(0);
  const scoreArr = useRef<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateScore = (_score: number) => {
    score.current = _score;
    scoreArr.current.push(_score);
  };

  // Game Loop
  useInterval(
    () => {
      const inPractice = pController.inPractice;
      const scoreCalc =
        focusLevel >= BASE_FOCUS_LEVEL_REQUIRED
          ? mapRange(
              focusLevel,
              BASE_FOCUS_LEVEL_REQUIRED,
              100,
              0 + DIFF_OFFSET,
              100
            )
          : 0.0;
      pController.setAnglesForScore(scoreCalc);
      if (secondsRemaining !== prevSnapshotTime.current) {
        if (!inPractice) scoreArr.current.push(scoreCalc);

        prevSnapshotTime.current = secondsRemaining;
      }
    },
    isRunning && !pController.hasFinished ? 100 : null
  );

  const handleStartPause = () => {
    if (isRunning) pause();
    else {
      pourDbEntry.mutate({
        id: accountId,
        eventId: currentEvent.id,
        m: 'Safe',
        d: 'easy',
        ...(confirmed
          ? {
              name,
              claimed: true,
            }
          : {}),
      });
      start();
      pController.start();
    }
  };

  const exitGame = useCallback(() => {
    pController.stop();
    pause();
    goHome();
  }, [goHome, pController, pause]);

  useEffect(() => {
    if (abort) {
      exitGame();
    }
  }, [abort, exitGame]);

  return (
    <Container>
      {showIntro && !isRunning && (
        <PreGameDialog
          callback={handleStartPause}
          isLoading={!mockMindControl && (!headsetDetected || !headsetOn)}
          mode="Safe"
          exit={goHome}
        >
          {!mockMindControl && !headsetDetected ? (
            <LoaderWithMessage message="Detecting headset..." />
          ) : !mockMindControl && !headsetOn ? (
            <LoaderWithMessage
              message={`Headset quality is ${
                headsetQuality === HeadsetQuality.BAD ? 'BAD' : 'MEDIUM'
              }`}
            />
          ) : (
            <></>
          )}
        </PreGameDialog>
      )}
      {showOutro &&
        (pourDbEntry.isSuccess ? (
          <PostGameDialog
            callback={goHome}
            focusScore={(pController.percentageComplete ?? 0) * 100}
            score={pController.percentageComplete ?? 0}
            timeRemaining={pController.secondsRemaining}
            timeSpent={pController.pourTime - pController.secondsRemaining}
            mode="Safe"
            scoreCalculated={pController.scoreCalculated ?? false}
            pourId={pourDbEntry.data}
            scoreUnits="%"
          />
        ) : (
          <></>
        ))}
      {showOutro && pourDbEntry.isError && (
        <div>
          <h1>Error writing pour to db</h1>
          <p>{pourDbEntry.error.message}</p>
          <button onClick={goHome}>home</button>
        </div>
      )}
      {isRunning && !showOutro && (
        <ReactP5Wrapper
          sketch={mindSketch}
          _pourTime={pController.pourTime}
          _practiceTime={pController.practiceTime}
          beerIconUrl={currentEvent.beer_icon_path}
          beerIconSize={currentEvent.beer_icon_size}
          topColor={theme.colors.secondary}
          _noPracticeTime={true}
          _endOfPracticeTime={!pController.inPractice}
          // TODO: pass in seconds remaining from this game timer
          focusLevel={focusLevel}
          onComplete={() => pause()}
        />
      )}
      <ExitButton onClick={exitGame}>&times;</ExitButton>
    </Container>
  );
};

export default SafeMode;

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  place-content: center;
  gap: 16px;
`;

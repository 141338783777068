import useAngledGlassController from './useAngledGlassController';
import { UsePourController } from './types/pourController';

const useCoffeeController = (
  onCompleteCallback?: () => void,
  usePractice = true
): UsePourController => {
  return useAngledGlassController(onCompleteCallback, usePractice, true);
};

export default useCoffeeController;

export interface Event {
  id: number;
  created_at: string | null;
  name: string | null;
  image_path: string | null;
  beer_icon_path: string | null;
  beer_icon_size: number | null;
  body_left_image_path: string | null;
  body_right_image_path: string | null;
  body_image_tint: boolean;
  leaderboard_cut_off: string | null;
}

export interface ScoreTable {
  id: number;
  created_at: string;
  name: string;
  score: number;
  mode: string;
  event_id: number;
}

export enum Table {
  SCORE = 'score_test',
  EVENT = 'event',
  ACCOUNTS = 'accounts',
  POURS = 'pours',
  EMAIL = 'emails',
}

export interface Account {
  id: string;
  username: string;
  connection_id: string;
}

export interface Pour {
  id?: number;
  created_at: string | null;
  score: number;
  time_remaining: number | null;
  time_spent: number | null;
  completed: boolean | null;
  name: string | null;
  mode: string | null;
  skin: string;
  difficulty: string | null;
  account_id: string | null;
  claimed: boolean;
  event_id: number | null;
}

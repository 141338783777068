export const mapRange = (
  n: number,
  start1: number,
  stop1: number,
  start2: number,
  stop2: number
): number => {
  const newval = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  if (start2 < stop2) {
    return constrain(newval, start2, stop2);
  } else {
    return constrain(newval, stop2, start2);
  }
};

const constrain = (n: number, low: number, high: number): number => {
  return Math.max(Math.min(n, high), low);
};

export const getNumberWithOrdinal = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  const ordinal = n + (s[(v - 20) % 10] || s[v] || s[0]);
  console.log(`got ordinal ${ordinal}`);
  return ordinal;
};

export const calculateFinalScore = (scoreArr: number[]): number => {
  const avg = scoreArr.reduce((a, b) => a + b, 0) / scoreArr.length;
  return parseFloat((avg * 10).toFixed(2));
};

export const lerp = (start: number, end: number, t: number) => {
  return start + t * (end - start);
};

export const randomValue = <T>(arr: T[]): T =>
  arr[Math.floor(Math.random() * arr.length)];

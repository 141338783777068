import { Image, Vector } from 'p5';
import { P5CanvasInstance, Sketch, SketchProps } from '@p5-wrapper/react';
import chroma from 'chroma-js';

import VaporGrid from '../shared/vaporGrid';
import TopDisplay from '../shared/topDisplay';

interface MindGameProps extends SketchProps {
  focusLevel: number;
  topColor: string;
  beerIconUrl?: string | null;
  beerIconSize?: number | null;
  _pourTime: number;
  _practiceTime: number;
  _noPracticeTime?: boolean;
  _endOfPracticeTime?: boolean;
  onComplete: () => void;
}

export const TOP_H = 120;

export const mindSketch: Sketch<MindGameProps> = (p5) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let TYPEFACE: any;
  let pourTime = 0;
  let practiceTime = 0;
  let noPracticeTime = false;
  let endOfPracticeTime = false;
  let unboundedPracticeTimeOffset: number | null = null;
  let timeRemaining = practiceTime + pourTime;
  let topColor: string;

  let focusLevel = 0;
  let beerIconUrl = 'assets/beer-icon.png';
  let beerIconSize = 120;
  let onCompleteCallback: () => void;
  let grid: VaporGrid<MindGameProps>;
  let topDisplay: TopDisplay<MindGameProps>;
  let indicator: FocusIndicator;

  let emitter: BeerEmitter;
  let crossHair: CrossHair;

  let beerIcon: Image;
  let practiceImg: Image;

  const target = p5.createGraphics(600, 600);

  p5.updateWithProps = (props) => {
    if (typeof props.focusLevel == 'number') {
      focusLevel = props.focusLevel;
    }

    if (typeof props._pourTime == 'number') {
      pourTime = props._pourTime;
    }

    if (typeof props.beerIconUrl == 'string' && props.beerIconUrl != '') {
      if (beerIconUrl != props.beerIconUrl) {
        beerIconUrl = props.beerIconUrl;
        p5.loadImage(beerIconUrl, (image) => {
          beerIcon = image;
        });
      }
    }

    if (typeof props.beerIconSize == 'number' && props.beerIconSize > 0) {
      beerIconSize = props.beerIconSize;
    }

    if (typeof props._practiceTime == 'number') {
      practiceTime = props._practiceTime;
    }

    if (typeof props._noPracticeTime == 'boolean') {
      noPracticeTime = props._noPracticeTime;
      practiceTime = 0;
    }

    if (typeof props._endOfPracticeTime == 'boolean') {
      endOfPracticeTime = props._endOfPracticeTime;
    }

    if (typeof props.topColor === 'string') {
      topColor = props.topColor;
    }

    if (typeof props.onComplete == 'function') {
      onCompleteCallback = props.onComplete;
    }
  };

  p5.preload = () => {
    TYPEFACE = p5.loadFont('assets/ShareTechMono-Regular.ttf');
    beerIcon = p5.loadImage(beerIconUrl);
    practiceImg = p5.loadImage('assets/practice.png');
  };

  p5.setup = () => {
    p5.pixelDensity(1);
    p5.createCanvas(window.innerWidth, window.innerHeight);

    p5.textFont(TYPEFACE);

    grid = new VaporGrid<MindGameProps>(p5);
    topDisplay = new TopDisplay<MindGameProps>(p5, topColor);

    indicator = new FocusIndicator(p5, beerIcon);
    emitter = new BeerEmitter(p5, p5.width / 2, p5.height / 2, beerIcon);
    crossHair = new CrossHair(p5);

    timeRemaining = pourTime + practiceTime;
    console.log({
      timeRemaining,
      pourTime,
      practiceTime,
      noPracticeTime,
      endOfPracticeTime,
    });
  };

  p5.draw = () => {
    // currScore = p5.map(p5.noise(p5.frameCount / 25), 0, 1, 0, 1);
    const inPractice =
      timeRemaining - pourTime >= 0 || (noPracticeTime && !endOfPracticeTime);
    const duration = pourTime + practiceTime;
    if (p5.frameCount % 60 == 0) {
      console.log('curr fr: ', p5.frameRate());
    }

    p5.background(2, 20);

    if (
      noPracticeTime &&
      endOfPracticeTime &&
      unboundedPracticeTimeOffset === null
    ) {
      unboundedPracticeTimeOffset = p5.round(p5.millis());
    }

    let elapsedMillis = 0;

    if (!noPracticeTime || endOfPracticeTime) {
      elapsedMillis = p5.round(p5.millis());
      const elapsed = p5.round(
        (elapsedMillis - (unboundedPracticeTimeOffset ?? 0)) / 1000
      );
      timeRemaining = p5.map(elapsed, 0, duration, duration, 0, true);
    }

    const timeRemainingPct = p5.map(
      elapsedMillis,
      practiceTime * 1000,
      duration * 1000,
      0,
      100,
      true
    );

    grid.draw(inPractice ? 0 : focusLevel / 100);

    crossHair.draw(focusLevel / 100);

    target.clear(2, 2, 2, 10);
    target.background(2, 10);
    target.strokeWeight(3);
    target.stroke(topColor);
    target.fill(2);
    target.noFill();

    const size2 = p5.sin(p5.frameCount / 20.111);
    const inc = p5.width / 9;
    const multiplier = 10;

    const fill = [...chroma(topColor).rgba()];
    const opa3 = p5.map(p5.constrain(focusLevel, 25, 100), 25, 100, 40, 255);
    const opa4 = p5.map(p5.constrain(focusLevel, 0, 100), 0, 100, 40, 255);

    fill[3] = opa3;
    target.stroke(fill);
    target.ellipse(
      target.width / 2,
      target.height / 2,
      inc * 3 + multiplier * size2
    );
    fill[3] = opa4;
    target.stroke(fill);
    target.ellipse(
      target.width / 2,
      target.height / 2,
      inc * 4 + multiplier * size2
    );

    p5.image(
      target,
      p5.width / 2 - target.width / 2,
      p5.height / 2 - target.height / 2
    );

    p5.stroke(topColor);
    topDisplay.draw(
      inPractice ? pourTime : timeRemaining,
      focusLevel,
      inPractice ? 0 : timeRemainingPct
    );

    drawMidTarget();

    if (focusLevel > 90) {
      emitter.emit();
    }
    emitter.draw();

    indicator.update(focusLevel);
    indicator.draw(beerIconSize);

    if (inPractice) {
      p5.push();
      p5.translate(0, -150);
      p5.fill(255);
      const w = 300;
      const h = 40;
      p5.image(practiceImg, p5.width / 2 - w / 2, p5.height * 0.9, w);
      const practiceProgress = p5.map(
        timeRemaining - pourTime,
        practiceTime,
        0,
        0,
        w
      );

      p5.noFill();
      p5.stroke(255);
      p5.rect(p5.width / 2 - w / 2, p5.height * 0.9 + h * 2, 300, h);
      p5.noStroke();
      p5.fill(255);
      p5.rect(
        p5.width / 2 - w / 2,
        p5.height * 0.9 + h * 2,
        practiceProgress,
        h
      );

      p5.pop();
    }

    if (timeRemaining <= 0) {
      onCompleteCallback();
      p5.noLoop();
    }
  };

  const drawMidTarget = () => {
    const mid = { x: p5.width / 2, y: p5.height / 2 };
    p5.noFill();
    const opa = p5.map(focusLevel, 0, 100, 0, 255);
    p5.stroke(255, opa);
    const lineDiam = 20;
    p5.line(mid.x, mid.y - lineDiam, mid.x, mid.y + lineDiam);
    p5.line(mid.x - lineDiam, mid.y, mid.x + lineDiam, mid.y);
    p5.ellipse(mid.x, mid.y, 30, 30);
  };
};

class FocusIndicator {
  p5: P5CanvasInstance<MindGameProps>;
  pos: Vector;
  trail: Vector[];
  MAX_TRAIL: number;
  r: number;
  img: Image;

  constructor(p5: P5CanvasInstance<MindGameProps>, _img: Image) {
    this.p5 = p5;
    this.pos = this.p5.createVector(this.p5.width / 2, this.p5.height / 2);
    this.trail = [];
    this.MAX_TRAIL = 2;
    this.r = 0;
    this.p5.textFont('Arial');
    this.img = _img;
  }

  update(_focusLevel: number) {
    const ease = 0.15;

    // if (frameCount % 60 == 0) console.log(this.trail)

    const targetR = this.p5.map(_focusLevel, 0, 100, 200, 0);
    const dr = targetR - this.r;
    this.r += dr * ease;

    const x = this.p5.width / 2 + this.p5.cos(this.p5.frameCount / 50) * this.r;
    const y =
      this.p5.height / 2 + this.p5.sin(this.p5.frameCount / 50) * this.r;

    this.trail.push(this.p5.createVector(x, y));

    if (this.trail.length > this.MAX_TRAIL) this.trail.shift();
  }

  draw(height: number) {
    this.p5.noStroke();
    this.trail.forEach((pos) => {
      this.p5.noStroke();
      this.p5.fill(255);
      const width = this.img.width * (height / this.img.height);
      this.p5.image(
        this.img,
        pos.x - width / 2,
        pos.y - height / 2,
        width,
        height
      );
    });
  }
}

class BeerEmitter {
  p5: P5CanvasInstance<MindGameProps>;
  pos: Vector;
  trail: BeerParticle[];
  MAX_TRAIL: 40;
  img: Image;

  constructor(
    p5: P5CanvasInstance<MindGameProps>,
    _x: number,
    _y: number,
    img: Image
  ) {
    this.p5 = p5;
    this.pos = this.p5.createVector(_x, _y);
    this.trail = [];
    this.MAX_TRAIL = 40;
    this.img = img;
  }

  emit() {
    if (this.trail.length < this.MAX_TRAIL) {
      this.trail.push(
        new BeerParticle(this.p5, this.pos.x, this.pos.y, this.img)
      );
    }
  }

  draw() {
    this.trail = this.trail.filter((t) => t.life > 0);
    this.trail.forEach((t) => {
      t.draw();
    });
  }
}

class BeerParticle {
  p5: P5CanvasInstance<MindGameProps>;
  origin: Vector;
  pos: Vector;
  dir: Vector;
  life: number;
  img: Image;

  constructor(
    p5: P5CanvasInstance<MindGameProps>,
    _x: number,
    _y: number,
    image: Image
  ) {
    this.p5 = p5;
    this.origin = this.p5.createVector(_x, _y);
    this.life = 255;
    this.pos = this.p5.createVector(_x, _y);
    this.dir = this.p5.createVector(
      this.p5.random(-1, 1),
      this.p5.random(-1, 1)
    );
    this.img = image;
  }

  draw() {
    if (this.life <= 0) return;
    this.pos.add(this.dir);
    const height = this.p5.map(this.life, 255, 0, 48, 0);
    const width = this.img.width * (height / this.img.height);
    this.p5.image(
      this.img,
      this.pos.x - width / 2,
      this.pos.y - height / 2,
      width,
      height
    );
    this.life -= 2;
  }
}

class CrossHair {
  p5: P5CanvasInstance<MindGameProps>;
  prevOffset: number;
  theta: number;
  PINK: number[];
  BLUE: number[];

  constructor(p5: P5CanvasInstance<MindGameProps>) {
    this.p5 = p5;
    this.prevOffset = 0;
    this.theta = 0;
    this.PINK = [225, 85, 227];
    this.BLUE = [111, 255, 246];
  }

  draw = (focusLevel: number) => {
    this.p5.push();
    this.p5.translate(this.p5.width / 2, this.p5.height / 2);
    this.p5.noFill();
    this.p5.stroke(255);

    const size = 220;
    this.p5.strokeWeight(2);
    const t = 30;
    const ease = 0.1;

    let targetOffset = this.p5.map(focusLevel, 0, 1, 1.0, 0);
    if (targetOffset < 0.1) targetOffset = 0.0;
    this.prevOffset += (targetOffset - this.prevOffset) * ease;

    this.prevOffset = this.p5.constrain(this.prevOffset, 0, 1);
    const opa = this.p5.map(this.prevOffset, 0, 1, 255, 0);

    this.p5.strokeWeight(3);
    const offset = 6;
    const max = 4;
    // inner circles
    this.p5.push();
    this.p5.rotate(-this.p5.sin(this.theta * this.p5.PI) * this.prevOffset);
    for (let i = 1; i <= max; i++) {
      const col = i % 2 == 0 ? this.PINK : this.BLUE;
      this.p5.fill([...col, opa]);
      this.p5.stroke(col);
      this.p5.push();
      this.p5.rotate((this.p5.PI / (max / 2)) * i);
      const innerTwo = 18;
      this.p5.ellipse(0, 0 - t * 2 + innerTwo, 4, 4);
      this.p5.pop();
    }
    this.p5.pop();

    this.p5.rotate(this.p5.sin(this.theta * this.p5.PI) * this.prevOffset * 2);
    this.theta += 0.01;
    if (this.prevOffset <= 0) this.theta = 0;
    // outer triangles
    for (let i = 1; i <= max; i++) {
      const col = i % 2 == 0 ? this.PINK : this.BLUE;
      this.p5.fill([...col, opa]);
      this.p5.stroke(col);
      this.p5.push();
      // translate(x, y);
      this.p5.rotate((this.p5.PI / (max / 2)) * i);
      // scale(0.5)
      this.p5.triangle(
        0,
        0 - t * 2,
        0 - t,
        0 - size / 2 + offset,
        0 + t,
        0 - size / 2 + offset
      );
      this.p5.pop();
    }

    this.p5.noFill();
    this.p5.strokeWeight(8);
    this.p5.stroke(255);
    this.p5.ellipse(0, 0, size - 2, size - 2);
    this.p5.pop();
  };
}

import { useState, useRef, useEffect, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';

import Countdown from '../Countdown';
import PreGameDialog from '../PreGameDialog';
import PostGameDialog from '../PostGameDialog';
import ExitButton from '../icons/ExitButton';

import useMindControl from '../../lib/useMindControl';
import useInterval from '../../lib/useInterval';
import usePourController from '../../lib/usePourController';
import { calculateFinalScore, mapRange } from '../../lib/utils';
import { AppState, useGlobalState } from '../../lib/globalStateProvider';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import { mindSketch } from '../games/mind/main';
import { LoaderWithMessage } from '../Loader';
import useCreatePour from '../../lib/db/useCreatePour';
import {
  HeadsetQuality,
  useLocalMindControl,
} from '../../lib/localMindControlProvider';
import useModeChanger from '../../lib/useModeChanger';
import { usePersonalDetails } from '../../lib/usePersonalDetails';

interface MindModeProps {
  accountId: string;
}

const MindMode = ({ accountId }: MindModeProps): JSX.Element => {
  const {
    pourParams,
    connectionStatusData,
    currentEvent,
    localHeadsetConnected,
    mockMindControl,
  } = useGlobalState();
  const { confirmed, name } = usePersonalDetails();
  const { changeMode } = useModeChanger();
  const { headsetQuality, headsetOn } = useLocalMindControl();
  const [showCountdown, setShowCountdown] = useState(false);
  const theme = useTheme();

  const pController = usePourController();
  const secondsRemaining = pController.secondsRemaining;
  const { start, pause, focusLevel, isRunning } = useMindControl(
    mockMindControl,
    secondsRemaining
  );

  const headsetDetected =
    connectionStatusData.headset_connection < 200 || localHeadsetConnected;

  const prevSnapshotTime = useRef(-1);

  const showIntro =
    !showCountdown && !isRunning && pController.secondsRemaining > 1;
  const showOutro = pController.hasFinished;

  if (currentEvent == null) {
    throw new Error('No event selected');
  }

  const pourDbEntry = useCreatePour(
    accountId,
    currentEvent.id,
    'Mind',
    '',
    'easy'
  );

  const goHome = useCallback(() => {
    changeMode(AppState.Home);
  }, [changeMode]);

  const BASE_FOCUS_LEVEL_REQUIRED = pourParams.minFocus;
  const DIFF_OFFSET = pourParams.minScoreMapping;

  // TODO: move into a game mode hook
  const score = useRef(0);
  const scoreArr = useRef<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateScore = (_score: number) => {
    score.current = _score;
    scoreArr.current.push(_score);
  };

  // Game Loop
  useInterval(
    () => {
      const inPractice = pController.inPractice;
      const scoreCalc =
        focusLevel >= BASE_FOCUS_LEVEL_REQUIRED
          ? mapRange(
              focusLevel,
              BASE_FOCUS_LEVEL_REQUIRED,
              100,
              0 + DIFF_OFFSET,
              100
            )
          : 0.0;
      if (secondsRemaining !== prevSnapshotTime.current) {
        if (!inPractice) scoreArr.current.push(scoreCalc);
        pController.setAnglesForScore(scoreCalc);

        prevSnapshotTime.current = secondsRemaining;
      }
    },
    isRunning && !pController.hasFinished ? 100 : null
  );

  useEffect(() => {
    if (isRunning && pController.hasFinished) {
      pause();
    }
  }, [isRunning, pController.hasFinished]);

  const handleStartPause = () => {
    if (isRunning) pause();
    else {
      pourDbEntry.mutate({
        id: accountId,
        eventId: currentEvent.id,
        m: 'Mind',
        d: 'easy',
        ...(confirmed
          ? {
              name,
              claimed: true,
            }
          : {}),
      });
      setShowCountdown(true);
    }
  };

  const onCountdownComplete = () => {
    start();
    pController.start();
    setShowCountdown(false);
  };

  const exitGame = useCallback(() => {
    pause();
    goHome();
  }, [goHome, pause]);

  return (
    <Container>
      {showIntro && !isRunning && (
        <PreGameDialog
          callback={handleStartPause}
          isLoading={!mockMindControl && (!headsetDetected || !headsetOn)}
          mode="Mind"
          exit={goHome}
        >
          {!mockMindControl && !headsetDetected ? (
            <LoaderWithMessage message="Detecting headset..." />
          ) : !mockMindControl && !headsetOn ? (
            <LoaderWithMessage
              message={`Headset quality is ${
                headsetQuality === HeadsetQuality.BAD ? 'BAD' : 'MEDIUM'
              }`}
            />
          ) : (
            <></>
          )}
        </PreGameDialog>
      )}
      {showOutro && pourDbEntry.isSuccess ? (
        <PostGameDialog
          callback={goHome}
          score={calculateFinalScore(scoreArr.current)}
          mode="Mind"
          pourId={pourDbEntry.data}
        />
      ) : (
        <></>
      )}
      {showOutro && pourDbEntry.isError && (
        <div>
          <h1>Error writing pour to db</h1>
          <p>{pourDbEntry.error.message}</p>
          <button onClick={goHome}>home</button>
        </div>
      )}
      {showCountdown && (
        <Countdown
          target={3}
          started={showCountdown}
          onEndCallback={onCountdownComplete}
        />
      )}
      {isRunning && !showOutro && (
        <ReactP5Wrapper
          sketch={mindSketch}
          _pourTime={pController.pourTime}
          _practiceTime={pController.practiceTime}
          beerIconUrl={currentEvent.beer_icon_path}
          beerIconSize={currentEvent.beer_icon_size}
          topColor={theme.colors.secondary}
          // TODO: pass in seconds remaining from this game timer
          focusLevel={focusLevel}
          onComplete={() => pause()}
        />
      )}
      <ExitButton onClick={exitGame}>&times;</ExitButton>
    </Container>
  );
};

export default MindMode;

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  place-content: center;
  gap: 16px;
`;

import useAngledGlassController from './useAngledGlassController';
import { UsePourController } from './types/pourController';

const useBeerController = (
  onCompleteCallback?: () => void,
  usePractice = true
): UsePourController => {
  return useAngledGlassController(onCompleteCallback, usePractice, false);
};

export default useBeerController;

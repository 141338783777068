import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTimer } from 'react-timer-hook';
import { useGlobalState } from './globalStateProvider';
import { mapRange } from './utils';
import { UsePourController } from './types/pourController';

import useInterval from './useInterval';
import useServo from './useServo';

const useAngledGlassController = (
  onCompleteCallback?: () => void,
  usePractice = true,
  invertGlassAngles = false
): UsePourController => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { cupDetected, setCupDetected, pourParams } = useGlobalState();
  const tapSetOpen = useRef<boolean>(false);
  const { setAngles, sendAnglesToServo } = useServo(
    pourParams.glassWorstAngle,
    pourParams.tapOffAngle
  );
  const [hasFinished, setHasFinished] = useState(false);
  const gameTime = new Date();
  const pourTime = new Date();
  gameTime.setSeconds(
    gameTime.getSeconds() +
      pourParams.maxGameDurationSeconds +
      (usePractice ? pourParams.gamePracticeDuration : 0)
  );
  pourTime.setSeconds(
    pourTime.getSeconds() +
      pourParams.pourDurationSeconds +
      (usePractice ? pourParams.gamePracticeDuration : 0)
  );

  const onComplete = () => {
    console.log('TIMER HAS COMPLETE');
    setHasFinished(true);
    // setCupDetected(false);

    if (onCompleteCallback) onCompleteCallback();

    sendAnglesToServo(pourParams.tapOffAngle, pourParams.glassWorstAngle);

    // TODO: have state managed in web app and pass through websocekt on change
    // sendWsMessage({ type: 'change_mode', data: AppState.Home });
  };

  const onStart = (initialTap?: number, initialGlass?: number) => {
    setHasFinished(false);
    tapSetOpen.current = false;
    gameTime.setSeconds(
      new Date().getSeconds() +
        pourParams.maxGameDurationSeconds +
        (usePractice ? pourParams.gamePracticeDuration : 0)
    );
    pourTime.setSeconds(
      new Date().getSeconds() +
        pourParams.pourDurationSeconds +
        (usePractice ? pourParams.gamePracticeDuration : 0)
    );
    setAngles(
      initialTap ?? pourParams.tapOffAngle,
      initialGlass ?? pourParams.glassWorstAngle
    );

    if (!cupDetected) {
      toast.error('Please place a glass in the holder', {
        style: { fontSize: '32px' },
      });
      sendAnglesToServo(pourParams.tapOffAngle, pourParams.glassWorstAngle);
    } else {
      console.log('starting timer...');
      start();
      startPour();
    }
  };

  const stopPour = () => {
    pause();
    pausePour();
    sendAnglesToServo(pourParams.tapOffAngle, pourParams.glassWorstAngle);
  };

  const {
    start,
    seconds: secondsRemaining,
    isRunning,
    pause,
  } = useTimer({
    expiryTimestamp: gameTime,
    onExpire: onComplete,
    autoStart: false,
  });

  const {
    start: startPour,
    seconds: pourSecondsRemaining,
    isRunning: isPourRunning,
    pause: pausePour,
    resume: resumePour,
  } = useTimer({
    expiryTimestamp: pourTime,
    onExpire: onComplete,
    autoStart: false,
  });

  const previousTime = useRef(-1);

  const isInPractice = useMemo(
    () => secondsRemaining - pourParams.maxGameDurationSeconds >= 0,
    [secondsRemaining, pourParams.maxGameDurationSeconds]
  );

  useEffect(() => {
    if (hasFinished) {
      sendAnglesToServo(pourParams.tapOffAngle, pourParams.glassWorstAngle);
      return;
    }
  }, [hasFinished, sendAnglesToServo]);

  useInterval(
    () => {
      if (isInPractice && !pourParams.practiceGlassMove) {
        // console.log('in practice, dont set servos');
        return;
      }

      if (hasFinished) {
        sendAnglesToServo(pourParams.tapOffAngle, pourParams.glassWorstAngle);
        return;
      }

      if (previousTime.current != secondsRemaining) {
        console.log(
          'secondsRemaining: %d, pourSecondsRemaining: %d, isPourRunning: %s',
          secondsRemaining,
          pourSecondsRemaining,
          isPourRunning ? 'true' : 'false'
        );
        if (
          secondsRemaining <= 3 ||
          (isPourRunning && pourSecondsRemaining <= 3)
        ) {
          // ease back tap and glass for end of pour
          sendAnglesToServo(null, pourParams.glassWorstAngle);

          console.log(
            'g %d, t %d',
            pourParams.glassWorstAngle,
            pourParams.tapOnAngle
          );
        } else {
          sendAnglesToServo(isInPractice ? pourParams.tapOffAngle : null);

          if (!isInPractice && !tapSetOpen.current) {
            pausePour();
          } else {
            resumePour();
          }
        }
        previousTime.current = secondsRemaining;
      }
      if (secondsRemaining <= 0 || pourSecondsRemaining <= 0) {
        onComplete();
      }
    },
    isRunning && !hasFinished ? 100 : null
  );

  const setAnglesForScore = useCallback(
    (score: number) => {
      const tapAngle =
        score < pourParams.minTapFocus
          ? pourParams.tapOffAngle
          : pourParams.tapOnAngle;
      tapSetOpen.current = tapAngle === pourParams.tapOnAngle;
      const glassAngle = invertGlassAngles
        ? mapRange(
            score,
            0.0,
            100,
            pourParams.glassBestAngle,
            pourParams.glassWorstAngle
          )
        : mapRange(
            score,
            0.0,
            100,
            pourParams.glassWorstAngle,
            pourParams.glassBestAngle
          );

      setAngles(tapAngle, glassAngle);
    },
    [
      setAngles,
      pourParams.tapOffAngle,
      pourParams.tapOnAngle,
      pourParams.glassWorstAngle,
      pourParams.glassBestAngle,
      pourParams.minTapFocus,
      invertGlassAngles,
    ]
  );

  return {
    pourTime: pourParams.maxGameDurationSeconds,
    practiceTime: usePractice ? pourParams.gamePracticeDuration : 0,
    hasFinished,
    start: onStart,
    secondsRemaining,
    setAnglesForScore,
    isPouring: isPourRunning,
    stop: stopPour,
    inPractice: isInPractice,
  };
};

export default useAngledGlassController;

import { useCallback, useContext } from 'react';

import {
  AppState,
  transformSafeColourToRgbArray,
  transformToRgb,
  useGlobalState,
} from './globalStateProvider';
import { WebSocketContext } from './sockets/ws-provider';
import { usePersonalDetails } from './usePersonalDetails';

const useModeChanger = () => {
  const {
    setCurrentMode,
    pourParams,
    robotDemoMode,
    setSkin,
    sendVaultStatusMessage,
    sendVaultMessagesMessage,
    vaultScreenParams,
  } = useGlobalState();
  const { reset } = usePersonalDetails();
  const { sendWsMessage } = useContext(WebSocketContext);

  const changeMode = useCallback(
    (mode: AppState, skin?: string) => {
      reset();
      if (skin) {
        setSkin(skin);
      }
      if (robotDemoMode) {
        setCurrentMode(mode);
        if (mode === AppState.Home) {
          sendVaultStatusMessage({
            mode: 'message',
          });
          sendVaultMessagesMessage({ ...vaultScreenParams });
        }
      } else {
        if (mode === AppState.Home) {
          sendWsMessage({
            type: 'change_default_angles',
            data: {
              glass_best_angle: pourParams.glassBestAngle,
              glass_worst_angle: pourParams.glassWorstAngle,
              tap_off_angle: pourParams.tapOffAngle,
              tap_on_angle: pourParams.tapOnAngle,
            },
          });
          sendVaultStatusMessage({
            mode: 'message',
          });
          sendVaultMessagesMessage({ ...vaultScreenParams });
          sendWsMessage({
            type: 'safe_v2_reset',
            data: {
              defaultStageColour: transformToRgb(pourParams.safeDefaultColour),
              completedStageColour: transformToRgb(
                pourParams.safeCompletedColour
              ),
              currentStageColour: transformToRgb(
                pourParams.safeInProgressColour
              ),
              idleColours: transformSafeColourToRgbArray(
                pourParams.safeIdleSelection,
                pourParams.safeIdleCustomColour
              ),
              idleColoursInterval: pourParams.safeIdleInterval,
              completeColours: transformSafeColourToRgbArray(
                pourParams.safeCompleteSelection,
                pourParams.safeCompleteCustomColour
              ),
              completeColoursInterval: pourParams.safeCompleteInterval,
              animate: true,
            },
          });
          setTimeout(() => {
            sendWsMessage({ type: 'change_mode', data: mode });
          }, 500);
        } else {
          sendWsMessage({ type: 'change_mode', data: mode });
        }
      }
    },
    [
      robotDemoMode,
      vaultScreenParams,
      sendWsMessage,
      pourParams.safeDefaultColour,
      pourParams.safeCompletedColour,
      pourParams.safeInProgressColour,
      pourParams.safeIdleSelection,
      pourParams.safeCompleteSelection,
    ]
  );

  return { changeMode };
};

export default useModeChanger;

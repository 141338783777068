import { useState, useRef, useCallback, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import useSound from 'use-sound';
import { ReactP5Wrapper } from '@p5-wrapper/react';

import Countdown from '../Countdown';
import PreGameDialog from '../PreGameDialog';
import PostGameDialog from '../PostGameDialog';
import ExitButton from '../icons/ExitButton';

import useMindControl from '../../lib/useMindControl';
import useInterval from '../../lib/useInterval';
import usePourController from '../../lib/usePourController';
import { calculateFinalScore, mapRange } from '../../lib/utils';
import { AppState, useGlobalState } from '../../lib/globalStateProvider';
import { raceSketch, SoundEffectType, RaceModeSkin } from '../games/race/main';
import { LoaderWithMessage } from '../Loader';
import useCreatePour from '../../lib/db/useCreatePour';
import {
  HeadsetQuality,
  useLocalMindControl,
} from '../../lib/localMindControlProvider';

import useModeChanger from '../../lib/useModeChanger';
import { usePersonalDetails } from '../../lib/usePersonalDetails';

type RaceSound = {
  url: string;
  sprite?: Record<string, [number, number]>;
  primarySprite?: string;
  secondarySprite?: string;
};

type RaceModeSkinAndSound = RaceModeSkin & {
  sound: {
    acceleration?: RaceSound;
    music?: RaceSound;
    idle?: RaceSound;
    rev?: RaceSound;
  };
};

const RaceModeSkins: Record<string, RaceModeSkinAndSound> = {
  road: {
    sound: {
      acceleration: {
        url: 'assets/race-acceleration.mp3',
        sprite: {
          accel: [0, 33000],
          end: [33000, 3700],
        },
        primarySprite: 'accel',
        secondarySprite: 'end',
      },
      idle: {
        url: 'assets/race-car-idle.mp3',
      },
      // music: {
      //   url: 'assets/race-ambient-theme.mp3',
      // },
      rev: {
        url: 'assets/race-car-revving.mp3',
        sprite: {
          rev: [5500, 2700],
        },
        primarySprite: 'rev',
      },
    },
    colors: {
      FOG: '#2c2546',
      LIGHT: {
        road: '#6B6B6B',
        grass: '#10AA10',
        rumble: '#555555',
        lane: '#CCCCCC',
      },
      DARK: { road: '#696969', grass: '#009A00', rumble: '#BBBBBB' },
      START: { road: 'white', grass: 'white', rumble: 'white' },
      FINISH: { road: 'black', grass: 'black', rumble: 'black' },
    },
    scaleMultiplier: 0.3,
    playerBounce: true,
    playerStraightImageUrls: ['assets/race-player-straight.png'],
    skyImageUrl: 'assets/race-sky.png',
    billboardImages: [
      {
        url: 'assets/race-billboard.png',
        scale: 1,
      },
    ],
    parallaxImageUrls: [
      'assets/race-far-clouds.png',
      'assets/race-near-clouds.png',
      'assets/race-far-mountains.png',
      'assets/race-mountains.png',
      'assets/race-trees.png',
    ],
    sceneryImages: [
      {
        url: 'assets/race-bush.png',
        offset: 1,
        minMultiplier: -1.5,
        maxMultiplier: 2,
      },
      {
        url: 'assets/race-tree.png',
        offset: 1.5,
        minMultiplier: -1.7,
        maxMultiplier: 2,
      },
    ],
  },
  space: {
    sound: {
      acceleration: {
        url: 'assets/space-acceleration.mp3',
      },
      idle: {
        url: 'assets/space-idle.mp3',
      },
      // music: {
      //   url: 'assets/race-ambient-theme.mp3',
      // },
      rev: {
        url: 'assets/space-rev.mp3',
      },
    },
    colors: {
      // FOG: '#2c2546',
      LIGHT: {
        road: '#6B6B6B66',
        grass: '#00000000',
        rumble: '#6cbe45',
        lane: '#6cbe45',
      },
      DARK: {
        road: '#69696966',
        grass: '#00000000',
        rumble: '#6cbe45',
      },
      START: { road: 'white', grass: 'white', rumble: 'white' },
      FINISH: { road: 'black', grass: 'black', rumble: 'black' },
    },
    glowLines: true,
    playerBounce: false,
    drawRoadFirst: false,
    scaleMultiplier: 0.4,
    playerStraightImageUrls: ['assets/space-player-straight.gif'],
    skyImageUrl: 'assets/space-sky.png',
    fitSkyToHeight: true,
    billboardSpacingMin: 50,
    billboardSpacingMax: 200,
    billboardRandomise: true,
    billboardImages: [
      {
        url: 'assets/space-astronaut.png',
        scale: 2,
        brandScale: 2,
        graphicPositions: [[0.68, 0.39]],
      },
      {
        url: 'assets/race-billboard.png',
        scale: 5,
      },
      {
        url: 'assets/space-satellite.png',
        scale: 2,
        brandScale: 2,
        graphicPositions: [
          [0.25, 0.25],
          [0.75, 0.75],
        ],
      },
    ],
    parallaxImageUrls: [],
    sceneryImages: [
      {
        url: 'assets/space-asteroid-01.png',
        offset: 1,
        minMultiplier: -1.5,
        maxMultiplier: 2,
        scale: 10,
        verticalOffsetMax: 1.5,
        verticalOffsetMin: -1.5,
      },
      {
        url: 'assets/space-asteroid-02.png',
        offset: 1.5,
        minMultiplier: -1.7,
        maxMultiplier: 2,
        scale: 3,
        verticalOffsetMax: 2,
        verticalOffsetMin: -2,
      },
    ],
  },
};

interface RaceModeProps {
  accountId: string;
  skin?: string;
}

const RaceMode = ({ accountId, skin = 'road' }: RaceModeProps): JSX.Element => {
  const {
    pourParams,
    connectionStatusData,
    currentEvent,
    localHeadsetConnected,
    mockMindControl,
  } = useGlobalState();
  const raceModeSkin = RaceModeSkins[skin] ?? RaceModeSkins.road;

  const { confirmed, name } = usePersonalDetails();
  const theme = useTheme();
  const { changeMode } = useModeChanger();
  const { headsetQuality, headsetOn } = useLocalMindControl();
  const [showCountdown, setShowCountdown] = useState(false);
  const [finalDistance, setFinalDistance] = useState(0);
  const [playMusic, { stop: stopMusic }] = useSound(
    raceModeSkin.sound.music ? raceModeSkin.sound.music.url : [],
    {
      soundEnabled: !!raceModeSkin.sound.music,
      volume: 0.6,
      loop: true,
    }
  );
  const [idlePlaying, setIdlePlaying] = useState(false);
  const [playIdle, { stop: stopIdle, duration: idleDuration }] = useSound(
    raceModeSkin.sound.idle ? raceModeSkin.sound.idle.url : [],
    {
      soundEnabled: !!raceModeSkin.sound.idle,
      volume: 1,
      loop: true,
    }
  );
  const [revPlaying, setRevPlaying] = useState(false);
  const [playRev, { stop: stopRev, duration: revDuration }] = useSound(
    raceModeSkin.sound.rev ? raceModeSkin.sound.rev.url : [],
    {
      soundEnabled: !!raceModeSkin.sound.rev,
      sprite: raceModeSkin.sound.rev?.sprite,
      volume: 1,
    }
  );
  const [accelPlaying, setAccelPlaying] = useState(false);
  const [playAccel, { stop: stopAccel, duration: accelDuration }] = useSound(
    raceModeSkin.sound.acceleration ? raceModeSkin.sound.acceleration.url : [],
    {
      soundEnabled: !!raceModeSkin.sound.acceleration,
      sprite: raceModeSkin.sound.acceleration?.sprite,
      volume: 1,
    }
  );

  const pController = usePourController();
  const secondsRemaining = pController.hasFinished
    ? 0
    : pController.secondsRemaining;
  const { start, pause, focusLevel, isRunning } = useMindControl(
    mockMindControl,
    secondsRemaining
  );

  const headsetDetected =
    connectionStatusData.headset_connection < 200 || localHeadsetConnected;

  const prevSnapshotTime = useRef(-1);

  const showIntro =
    !showCountdown && !isRunning && pController.secondsRemaining > 1;
  const showOutro = pController.hasFinished;

  if (currentEvent == null) {
    throw new Error('No event selected');
  }

  const pourDbEntry = useCreatePour(
    accountId,
    currentEvent.id,
    'Race',
    skin,
    'easy'
  );

  const goHome = useCallback(() => {
    stopMusic();
    stopIdle();
    stopRev();
    stopAccel();
    changeMode(AppState.Home);
  }, [changeMode, stopMusic, stopIdle, stopRev, stopAccel]);

  useEffect(() => {
    return () => {
      stopMusic();
      stopIdle();
      stopRev();
      stopAccel();
    };
  }, [stopAccel, stopMusic, stopRev, stopIdle]);

  const BASE_FOCUS_LEVEL_REQUIRED = pourParams.minFocus;
  const DIFF_OFFSET = pourParams.minScoreMapping;

  // TODO: move into a game mode hook
  const score = useRef(0);
  const scoreArr = useRef<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateScore = (_score: number) => {
    score.current = _score;
    scoreArr.current.push(_score);
  };

  // Game Loop
  useInterval(
    () => {
      const sRemain = pController.secondsRemaining;
      const inPractice = pController.inPractice;
      const scoreCalc =
        focusLevel >= BASE_FOCUS_LEVEL_REQUIRED
          ? mapRange(
              focusLevel,
              BASE_FOCUS_LEVEL_REQUIRED,
              100,
              0 + DIFF_OFFSET,
              100
            )
          : 0.0;
      if (sRemain !== prevSnapshotTime.current) {
        if (!inPractice) scoreArr.current.push(scoreCalc);
        pController.setAnglesForScore(scoreCalc);

        prevSnapshotTime.current = sRemain;
      }
    },
    isRunning && !pController.hasFinished ? 100 : null
  );

  useEffect(() => {
    if (isRunning && pController.hasFinished) {
      pause();
    }
  }, [isRunning, pController.hasFinished]);

  const handleStartPause = () => {
    if (isRunning) pause();
    else {
      pourDbEntry.mutate({
        id: accountId,
        eventId: currentEvent.id,
        m: 'Race',
        d: 'easy',
        ...(confirmed
          ? {
              name,
              claimed: true,
            }
          : {}),
      });
      setShowCountdown(true);
    }
  };

  const onCountdownComplete = () => {
    start();
    pController.start();
    setShowCountdown(false);
    playMusic();
  };

  const exitGame = useCallback(() => {
    pause();
    goHome();
  }, [goHome, pause]);

  const playSoundEffect = useCallback(
    (type: SoundEffectType): void => {
      switch (type) {
        case SoundEffectType.Idle:
          if (idleDuration != null && !idlePlaying) {
            playIdle();
            setIdlePlaying(true);
          }
          break;
        case SoundEffectType.Accelerate:
          if (
            raceModeSkin.sound.acceleration &&
            accelDuration != null &&
            !accelPlaying
          ) {
            const { primarySprite } = raceModeSkin.sound.acceleration;
            if (primarySprite) {
              playAccel({ id: primarySprite });
            } else {
              playAccel();
            }
            setAccelPlaying(true);
          }
          break;
        case SoundEffectType.End:
          if (
            raceModeSkin.sound.acceleration &&
            accelDuration != null &&
            !accelPlaying
          ) {
            const { secondarySprite } = raceModeSkin.sound.acceleration;
            if (secondarySprite) {
              playAccel({ id: secondarySprite });
              setAccelPlaying(true);
            }
          }
          break;
        case SoundEffectType.Rev:
          if (raceModeSkin.sound.rev && revDuration != null && !revPlaying) {
            const { primarySprite } = raceModeSkin.sound.rev;
            if (primarySprite) {
              playRev({ id: primarySprite });
              setTimeout(() => {
                setRevPlaying(false);
              }, (raceModeSkin.sound.rev?.sprite || {})[primarySprite][1]);
            } else {
              playRev();
              setTimeout(() => {
                setRevPlaying(false);
              }, revDuration);
            }
            setRevPlaying(true);
          }
          break;
      }
    },
    [
      raceModeSkin,
      playIdle,
      playAccel,
      playRev,
      idlePlaying,
      revPlaying,
      revDuration,
      idleDuration,
      accelPlaying,
      accelDuration,
    ]
  );

  const stopSoundEffect = useCallback(
    (type: SoundEffectType): void => {
      switch (type) {
        case SoundEffectType.Idle:
          stopIdle();
          setIdlePlaying(false);
          break;
        case SoundEffectType.Accelerate:
          stopAccel();
          setAccelPlaying(false);
          break;
        case SoundEffectType.Rev:
          stopRev();
          setRevPlaying(false);
          break;
      }
    },
    [stopIdle, stopAccel, stopRev]
  );

  return (
    <Container>
      {showIntro && !isRunning && (
        <PreGameDialog
          callback={handleStartPause}
          isLoading={!mockMindControl && (!headsetDetected || !headsetOn)}
          mode="Mind"
          exit={goHome}
        >
          {!mockMindControl && !headsetDetected ? (
            <LoaderWithMessage message="Detecting headset..." />
          ) : !mockMindControl && !headsetOn ? (
            <LoaderWithMessage
              message={`Headset quality is ${
                headsetQuality === HeadsetQuality.BAD ? 'BAD' : 'MEDIUM'
              }`}
            />
          ) : (
            <></>
          )}
        </PreGameDialog>
      )}
      {showOutro && pourDbEntry.isSuccess ? (
        <PostGameDialog
          callback={goHome}
          focusScore={calculateFinalScore(scoreArr.current)}
          score={finalDistance}
          dialogStartText="You travelled"
          scoreUnits="m"
          mode="Race"
          pourId={pourDbEntry.data}
        />
      ) : (
        <></>
      )}
      {showOutro && pourDbEntry.isError && (
        <div>
          <h1>Error writing pour to db</h1>
          <p>{pourDbEntry.error.message}</p>
          <button onClick={goHome}>home</button>
        </div>
      )}
      {showCountdown && (
        <Countdown
          target={3}
          started={showCountdown}
          onEndCallback={onCountdownComplete}
        />
      )}
      {isRunning && (
        <ReactP5Wrapper
          sketch={raceSketch}
          _pourTime={pController.pourTime}
          _practiceTime={pController.practiceTime}
          // TODO: pass in seconds remaining from this game timer
          skin={raceModeSkin}
          beerIconUrl={currentEvent.beer_icon_path}
          beerIconSize={currentEvent.beer_icon_size}
          topColor={theme.colors.secondary}
          focusLevel={focusLevel}
          onComplete={(distance: number) => {
            stopSoundEffect(SoundEffectType.Accelerate);
            stopSoundEffect(SoundEffectType.Idle);
            playSoundEffect(SoundEffectType.End);
            setFinalDistance(distance);
            pause();
          }}
          playSoundEffect={playSoundEffect}
          stopSoundEffect={stopSoundEffect}
        />
      )}
      <ExitButton onClick={exitGame}>&times;</ExitButton>
    </Container>
  );
};

export default RaceMode;

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  place-content: center;
  gap: 16px;
`;
